<template>
  <div
    v-if="isActive"
    id="nav"
    class="fki-footer-navbar"
    role="navigation"
  >
    <span class="fki-copyright">&copy; {{ copyright }} </span>
    <ul class="fki-footer-nav">
      <li
        v-for="item, index in items"
        :key="index"
        class="fki-footer-nav-item"
      >
        <router-link
          v-if="item.slug !== 'dailiesservices'"
          :to="`/${item.slug}`"
          :class="item.color"
          class="fki-footer-nav-item-link"
        >
          {{ item.name }}
        </router-link>
        <a
          v-if="item.slug == 'dailiesservices'"
          href="https://dailiesservices.fotokem.com"
          :class="item.color"
          class="fki-footer-nav-item-link"
        >
          {{ item.name }}
        </a>
      </li>
    </ul>
    <div class="fki-social">
      <span class="fki-social-icon">
        <a
          href=""
          target="_blank"
        >
          <img
            :src="twitterIcon"
            alt="Twitter"
          >
        </a>
      </span>
      <span class="fki-social-icon">
        <a
          href=""
          target="_blank"
        >
          <img
            :src="instagramIcon"
            alt="Instagram"
          >
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import twitterIcon from '@/assets/twitter.png'
import instagramIcon from '@/assets/instagram.png'

export default {
  name: 'FkiFooterNavbar',
  props: {
    items: {
      default: () => [
        {
          name: 'Careers',
          color: 'purple',
          slug: 'careers'
        },
        {
          name: 'News',
          color: 'blue',
          slug: 'news'
        },
        {
          name: 'Film Processing & Scanning',
          color: 'orange',
          slug: 'dailiesservices'
        },
        {
          name: 'Download',
          color: 'pink',
          slug: 'download'
        },
        {
          name: 'Terms',
          color: 'yellow',
          slug: 'terms'
        }
      ],
      type: Array
    },
    copyright: {
      type: String,
      default: '2022 Foto-Kem Industries, Inc. All rights reserved.'
    },
    isActive: Boolean,
    isMobile: Boolean
  },
  data () {
    return {
      instagramIcon: instagramIcon,
      twitterIcon: twitterIcon
    }
  }
}
</script>

<style lang="scss" scoped>
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

  .fki-footer-navbar {
    width:100%;
    -webkit-font-smoothing: none;
    text-align: center;
    flex-direction: column-reverse;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    .fki-copyright{
      height: 18px;
      opacity: .46;
      margin: 0 0 0 0px;
      font-size: 11px;
      line-height: 1;
      display: flex;
      vertical-align: middle;
      -webkit-font-smoothing: none;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    .fki-footer-nav {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
       flex-direction: column;
       padding: 0;
      .fki-footer-nav-item {
        display: flex;
        float: left;
        align-items:center;
        justify-content: center;
        position: relative;
        text-align: center;
        clear: none;
        height:20px;
        margin: 0 0.44vw;
        text-transform: capitalize;
        opacity: .46;
        .fki-footer-nav-item-link {
          text-decoration: none;
          color:#fff;
          line-height: 1;
          font-size: 11px;
          font-weight: 400;
        }
         &:hover {
          cursor: pointer;
          opacity:.9;
        }
      }
    }
    .fki-social{
      display: flex;
      height: 20px;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      .fki-social-icon{
        float: left;
        opacity:.36;
        -webkit-font-smoothing: antialiased;
        clear: none;
        display: block;
        height: auto;
        width: auto;
        margin:0 11px;
        height: 20px;
        transition: all .55s cubic-bezier(.25,.8,.25,1);
        &:hover{
          cursor: pointer;
          opacity:.9;
        }
      }
    }
    .fki-footer-nav .fki-footer-nav-item{
      .fki-footer-nav-item-link{
        letter-spacing: 1px;
        line-height: 1;
        font-size: 11px;
        vertical-align: middle;
        display: inline-block;
        transition: all .55s cubic-bezier(.25,.8,.25,1);
        &.router-link-exact-active {
          &.yellow{
          color: $fki-yellow;
          }
          &.green{
            color: $fki-green;
          }
          &.blue{
            color: $fki-blue;
          }
          &.pink{
            color: $fki-pink;
          }
          &.purple{
            color: $fki-purple;
          }
          &.orange{
            color: $fki-orange;
          }
        }
      }
    }
  }

@media screen and (min-width: 320px) {
  .fki-footer-navbar {
    font-size:11px;
    flex-wrap: nowrap;
    .fki-social{
      opacity:.46;
      height: auto;
      width: auto;
      margin:0 11px;
      -webkit-font-smoothing: antialiased;
    }
  }
}
@media screen and (min-width: 650px) {
  .fki-footer-navbar {
     height:70px;
      flex-direction: row;
    .fki-footer-nav{
       flex-direction: row;
    }
     .fki-social{
      flex-direction: row;
     }
     .fki-copyright {
       align-items: center;
       flex-direction: row;
       justify-content:center;
       margin: 0 30px 0 0px;
       .terms,  .copy, .footer-link{
        display: block;
        clear:none;
        float: left;
      }
    }
  }
}

 @media screen and (min-width: 768px) {
    .fki-footer-nav {
      margin: 0;
      padding: 0;
      opacity: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transform: translate(0,0);
      transition: transform .55s,opacity .5s ease;
    }
  }
@media screen and (min-width: 1024px) {
  .fki-footer-navbar {
    bottom:0;
    font-size:11px;
    height: 50px;
    .fki-copyright{
      margin: 10px 30px 10px 0px;
      font-size: 11px;
      display: block;
      line-height: 1.75;
    }
  }
}

</style>
