<template>
  <fki-loader :loading="loading" />

  <fki-container
    v-for="address, i in currentFacility.addresses"
    :key="i"
    class="fki-contact-box"
    width="80%"
    height="100vh"
    column
  >
    <div class="row">
      <div class="fki-contact-info">
        <div class="address">
          <h3>Address</h3> <address>
            <a
              target="_blank"
              :href="getMapLinkBase() + `${address.street1}%20${address.city}%20${ address.state}%20${address.zip}`"
              class="ng-binding"
            > {{ address.street1 }} <br> {{ address.city }}, {{ address.state }} {{ address.zip }} </a>
          </address>
        </div>
        <div
          class="telephone"
        >
          <div
            v-for="telephone, index in currentFacility.telephones"
            :key="index"
          >
            <h3>
              {{ telephone.name }}
            </h3> <p>
              <a
                :href="`tel:+${formatTelephone(telephone.number)}`"
              >+{{ telephone.number }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="fki-map-container">
        <GMapMap
          :center="currentFacility.center"
          :zoom="17"
          :disable-default-ui="true"
          map-type-id="terrain"
          :options="mapOptions"
          style="height: 350px"
        >
          <g-map-marker
            v-if="currentFacility.marker"
            ref="myMarker"
            :icon="{
              url: markerIcon,
              scaledSize: {width: 24, height: 24},
            }"

            :position="currentFacility.marker"
          />
        </GMapMap>
      </div>
    </div>
    <div
      v-if="currentFacility.hasForm === '1'"
      class="fki-form-container"
    >
      <h3>Send us a message</h3>
      <form
        id="send-message"
        name="messageForm"
      >
        <div class="row">
          <div class="col">
            <input
              v-model="eMessage.name"
              type="text"
              placeholder="Name*"
              required
              autofocus=""
              class="fki-form-input"
              @focus="closeDropdown()"
              @blur="v$.eMessage.name.$touch()"
            >
            <p
              v-for="error of v$.eMessage.name.$errors"
              :key="error.$uid"
              class="error"
            >
              {{ error.$message }} for name
            </p>
            <input
              v-model="eMessage.email"
              type="email"
              placeholder="Email*"
              required=""
              class="fki-form-input"
              @focus="closeDropdown()"
              @blur="v$.eMessage.email.$touch()"
            >
            <p
              v-for="error of v$.eMessage.email.$errors"
              :key="error.$uid"
              class="error"
            >
              {{ error.$message }} for email
            </p><input
              v-model="eMessage.phone"
              type="text"
              placeholder="Phone"
              class="fki-form-input"
              @focus="closeDropdown()"
            >
          </div>
          <div class="col col-right">
            <input
              v-model="eMessage.department"
              hidden
              placeholder="Choose Service / Department"
            > <div class="fki-dropdown">
              <div
                v-if="showDepartmentDropdown"
                class="fki-dropdown-overlay"
                @click="closeDropdown()"
              /> <p
                v-if="!showDepartmentDropdown"
                class="right-arrow"
                @click="openDropdown('department')"
              >
                {{ eMessage.department }}
              </p>
              <div
                v-if="showDepartmentDropdown"
                class="fki-dropdown-content"
                :class="{open:showDepartmentDropdown}"
              >
                <p
                  v-for="item, index in categories"
                  :key="index"
                  class="fki-option"
                  @click="selectValue(item)"
                >
                  {{ item.description }}
                </p>
              </div>
            </div>
            <input
              v-model="eMessage.subject"
              hidden
              placeholder="Choose Subject"
            >
            <div class="fki-dropdown">
              <div
                v-if="showSubjectDropdown"
                class="fki-dropdown-overlay"
                @click="closeDropdown()"
              /> <p
                v-if="!showSubjectDropdown"
                class="right-arrow"
                @click="openDropdown('subject')"
              >
                {{ eMessage.subject }}
              </p> <div
                v-if="showSubjectDropdown"
                class="fki-dropdown-content"
                :class="{open:showSubjectDropdown}"
              >
                <p
                  v-for="item, index in subjects"
                  :key="index"
                  class="fki-option"
                  @click="selectSubject(item)"
                >
                  {{ item }}
                </p>
              </div>
            </div>
            <textarea
              v-model="eMessage.message"
              maxlength="500"
              placeholder="Message"
              class="fki-form-textarea"
              @focus="closeDropdown()"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-left col">
            <div class="recaptch">
              <vue-recaptcha
                ref="recaptcha"
                sitekey="6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3"
                theme="light"
                @verify="verifyGRe"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-left col">
            <button
              type="button"
              class="button"
              :class="{disabled:loading}"
              @click.stop="eMessageSubmit(eMessage)"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </fki-container>
  <fki-container-popup
    :is-active="popupResponseOpen"
    overlay
    class=""
    persistent
    color="#424242"
    width="45%"
    @close="closePopupResponse"
  >
    <div
      class="fki-email-message-response"
      :class="messageReponse.error? 'error': 'success'"
    >
      <h3>
        {{ messageReponse.message }}
      </h3>
    </div>
  </fki-container-popup>
</template>

<script>
import Api from '@/services/api'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { FKIContainer, FKIContainerPopup } from '@/components/FKIContainer'
import { VueRecaptcha } from 'vue-recaptcha'
// import { createDeviceDetector } from 'next-vue-device-detector'
import { FKILoader } from '@/components/FKILoader'
// export const device = createDeviceDetector()
import ribbon from '@/assets/fki-ribbon.png'
import { GMapMarker } from '@fawmi/vue-google-maps'
export default {
  name: 'FkiContactFacility',
  components: {
    'fki-container': FKIContainer,
    'fki-container-popup': FKIContainerPopup,
    'vue-recaptcha': VueRecaptcha,
    'fki-loader': FKILoader,
    'g-map-marker': GMapMarker
  },
  props: {
    currentFacility: {
      type: Object,
      default: undefined
    }
  },
  emits: ['isLoading'],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      markers: [
        {
          position: {
            lat: 34.19759317353441, lng: -118.63171793253328
          }
        }
      ],
      messageReponse: {},
      gRecaptchaResponse: null,
      popupResponseOpen: false,
      loading: false,
      center: null,
      categories: [],
      subjects: [],
      showDepartmentDropdown: false,
      showSubjectDropdown: false,
      eMessage: {
        name: null,
        email: null,
        phone: '',
        department: 'Choose Service',
        subject: 'Choose Subject',
        message: '',
        gRecaptchaResponse: null
      },
      markerIcon: ribbon,
      mapOptions: {
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: true,
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#1d2c4d'
              }
            ]
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#8ec3b9'
              }
            ]
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#1a3646'
              }
            ]
          },
          {
            featureType: 'administrative.country',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#4b6878'
              }
            ]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#64779e'
              }
            ]
          },
          {
            featureType: 'administrative.province',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#4b6878'
              }
            ]
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#334e87'
              }
            ]
          },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [
              {
                color: '#023e58'
              }
            ]
          },
          {
            featureType: 'landscape.natural.terrain',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#ffeb3b'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#283d6a'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#6f9ba5'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#1d2c4d'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#023e58'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#3C7680'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#304a7d'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#98a5be'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#1d2c4d'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#2c6675'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#255763'
              },
              {
                weight: 2
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#b0d5ce'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#023e58'
              }
            ]
          },
          {
            featureType: 'transit',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#98a5be'
              }
            ]
          },
          {
            featureType: 'transit',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#1d2c4d'
              }
            ]
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#283d6a'
              }
            ]
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#3a4762'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#0e1626'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#4e6d70'
              }
            ]
          }
        ]
      }
    }
  },
  validations () {
    return {
      eMessage: {
        name: { required },
        email: { required, email }
      }
    }
  },
  async created () {
    console.log(this.currentFacility)
    this.getcCategories()
  },
  methods: {
    async verifyGRe (response) {
      this.gRecaptchaResponse = response
    },
    async eMessageSubmit (fields) {
      fields.gRecaptchaResponse = this.gRecaptchaResponse
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        this.loading = true

        try {
          // this.$emit('isLoading', true)
          const { data: result } = await Api().post('/send/message', fields)
          this.messageReponse = result
          this.popupResponseOpen = true
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
          // this.$emit('isLoading', false)
        }
      }
    },
    closePopupResponse () {
      this.messageReponse = null
      this.popupResponseOpen = false
      this.v$.$reset()
    },
    handleSuccess () {
      console.log('success')
    },
    handleError () {
      console.log('error')
    },
    async getServices (identifier) {
      this.isLoading = true

      try {
        const { data: result } = await Api().get('/services/c/' + identifier)

        this.subjects = []
        for (var i = 0; i < result.length; i++) {
          for (var j = 0; j < result[i].items.length; j++) {
            this.subjects.push(result[i].items[j].name)
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },
    async getcCategories () {
      this.isLoading = true

      try {
        const { data: result } = await Api().get('/categories/form')

        this.categories = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },
    getMapLinkBase () {
      const mapLinkBase = 'https://maps.google.com?q='
      // if (device.android || device.androidPhone) {
      //   mapLinkBase = 'geo:'
      // } else if (device.windows) {
      //   mapLinkBase = 'maps:'
      // }
      return mapLinkBase
    },
    selectValue (value) {
      this.eMessage.department = value.description
      this.getServices(value.identifier)
      this.closeDropdown()
    },
    selectSubject (value) {
      console.log(value)
      this.eMessage.subject = value
      this.closeDropdown()
    },
    openDropdown (name) {
      if (name === 'subject') {
        this.showSubjectDropdown = true
      }
      if (name === 'department') {
        this.showDepartmentDropdown = true
      }
    },
    closeDropdown () {
      this.showSubjectDropdown = false
      this.showDepartmentDropdown = false
    },
    formatTelephone (input) {
      var inputLow = input.toLowerCase()
      var phonenumber = ''
      for (var i = 0; i < inputLow.length; i++) {
        var character = inputLow.charAt(i)
        switch (character) {
          case '0':
            phonenumber += '0'
            break
          case '1':
            phonenumber += '1'
            break
          case '2':
            phonenumber += '2'
            break
          case '3':
            phonenumber += '3'
            break
          case '4':
            phonenumber += '4'
            break
          case '5':
            phonenumber += '5'
            break
          case '6':
            phonenumber += '6'
            break
          case '7':
            phonenumber += '7'
            break
          case '8':
            phonenumber += '8'
            break
          case '9':
            phonenumber += '9'
            break
          case 'a':
          case 'b':
          case 'c':
            phonenumber += '2'
            break
          case 'd':
          case 'e':
          case 'f':
            phonenumber += '3'
            break
          case 'g':
          case 'h':
          case 'i':
            phonenumber += '4'
            break
          case 'j':
          case 'k':
          case 'l':
            phonenumber += '5'
            break
          case 'm':
          case 'n':
          case 'o':
            phonenumber += '6'
            break
          case 'p':
          case 'q':
          case 'r':
          case 's':
            phonenumber += '7'
            break
          case 't':
          case 'u':
          case 'v':
            phonenumber += '8'
            break
          case 'w':
          case 'x':
          case 'y':
          case 'z':
            phonenumber += '9'
            break
          case '-':
          case ' ':
          case '(':
          case ')':
            phonenumber += ''
            break
          default:
            phonenumber += character
        }
      }
      return phonenumber
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .fki-email-message-response {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      width:100%;
      flex-direction: row;
      font-size:14px;
      padding: 12px 24px;
      &.error {
        background-color: #ff0000;
      }
      &.success {
        background-color: #628f2f;
      }
      p {
        font-size:14px;
      }
      h3 {
        color:#fff;
        text-align: center;
        font-size: 16px;
      }
    }
.fki-divider {
    height: 1px;
    background: #959595;
    opacity: .2;
    width: 100%;
}

    .fki-contact-box {
      // overflow-y: scroll;
      position: relative;
      height: 100%;
      padding:30px 0 0 0;
      min-height: 100px;
      max-width: 924px;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
     .fki-map-container {
      width: 100%;
      position: relative;
      min-height:250px;
      background-color: #fff;
    }
    .fki-form-container {
      display: flex;
      margin-top: 30px;
      clear: none;
      width: 100%;
      min-width: 300px;
      position: relative;
      overflow: hidden;
      min-height: 100px;
      max-width: 924px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      button {
        width: 100%;
        height: 45px;
        background-color: #E77926;
        color: #fff;
        border: 0;
        outline: 0;
        padding: 10px 15px;
        margin: 10px 0;
        font-size: 14px;
        text-align: center;
        max-width:100%;
        cursor: pointer;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        transition: all .55s cubic-bezier(.25,.8,.25,1);
        &:hover {
          background-color: #fff;
          color: #E77926;
          transition: all .55s cubic-bezier(.25,.8,.25,1);
        }
      }
      h3 {
        padding-top: 5px;
        text-align: left;
        margin: 0;
        text-transform: uppercase;
        color: #fff;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1px;
        margin: 0 0 8px;
      }
      .fki-form-input {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        background: #fff;
        color: #757575;
        height: 45px;
        border: 0;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
      }
      .fki-form-textarea {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        height: 140px;
        background: #fff;
        color: #757575;
        border: 0;
        outline: 0;
        font-size: 14px;
        font-family: Avenir, Helvetica, Arial, sans-serif;
      }
      .fki-dropdown {
        width: 100%;
        padding: 0;
        margin: 10px 0;
        background: #fff;
        color: #757575;
        height: 45px;
        border: 0;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        position: relative;
        float: left;
        cursor: pointer;
        p {
          color: #757575;
          font-size: 14px;
          margin: 0;
          padding: 13px 10px;
        }
        .fki-dropdown-content {
          opacity: 0;
          max-height: 175px;
          overflow: auto;
          display: none;
          height: 0;
          position: absolute;
          top: 0;
          padding: 8px 0;
          border: 1px solid #eee;
          z-index: 5000;
          box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
          &.open {
            display: block;
            opacity: 1;
            height: auto;
            position: absolute;
            min-height:45px;
            background: #fff;
            width: 100%;
            margin: 0;
            padding: 8px 0;
            left: 0;
            border: 1px solid #d3d3d3;
            box-sizing: border-box;
            top: 0;
          }
        }
        .fki-option {
          color: #757575;
          font-size: 14px;
          margin: 3px 0 0;
          padding: 8px;
          cursor: pointer;
          &:hover {
            cursor: pointer;
            background: #eee;
          }
        }
      }
      .right-arrow:before {
        position: absolute;
        color: #757575;
        right: 8px;
        top: 12px;
        content: '\25BC';
      }
      .fki-dropdown-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 4999;
        cursor: auto !important;
    }
    }
    .fki-contact-info {
      clear: none;
      padding-right: 30px;
      display: flex;
      width:50%;
      flex-direction: column;
      justify-content: flex-start;
      h3 {
        text-align: left;
        text-transform: uppercase;
        color: #fff;
        font-size: 14px;
        padding-top: 5px;
        margin: 0 0 5px;
        letter-spacing: 1px;
      }
      a {
        text-align: left;
        color: #fff;
        font-size: 14px;
        margin: 0;
        letter-spacing: 1px;
        padding-bottom: 10px;
        text-decoration: none;
      }
    }
    .fki-contact-box-container {
      overflow-y: scroll;
      padding:25px 30px;
      min-height: 100px;
      max-width: 924px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .row {
      display: flex;
      width: 100%;
      text-align: left;
      flex-direction: column;
      justify-content: space-between;
      .col {
        display: flex;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        flex-direction: column;
        &.col-right {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
    .address, .telephone {
      padding-top: 30px;
      width:100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    address, blockquote .small, blockquote footer, blockquote small, body, dd, dt {
      line-height: 1.428571429;
      text-align: left;
      font-style: normal;
    }
    form {
      width:100%;
    }
    .recaptch {
      padding: 10px 0;
      max-height: 80px !important;
      overflow: hidden;
      width: 100%;
      margin-bottom:10px;
    }
    .error {
      font-size:12px;
      font-weight: bold;
      margin:0;
      color: red;
    }

@media screen and (min-width: 1040px) {
  button {
     max-width:75px !important;
  }
  .row {
    flex-direction: row !important;
    .col  {
      width: 50% !important;
      padding-right: 20px;
      &.col-right {
        width: 50% !important;
        padding-right: 0;
        padding-left: 20px !important;
      }
      &.col-left {
        width: 50% !important;
        padding-left: 0;
        padding-right: 20px !important ;
      }
    }
  }
}
</style>
