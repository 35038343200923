<template>
  <div
    v-if="isActive"
    class="fki-sub-nav-container"
  >
    <div
      v-if="isActive"
      class="fki-sub-nav"
    >
      <ul class="fki-nav fki-navbar-nav">
        <li
          v-for="item, index in items"
          :key="index"
          class="fki-nav-item"
          @click="closeMenu"
        >
          <router-link
            :to="`/${item.slug}`"
            :class="item.color"
            class="fki-nav-item-link"
          >
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FkiSubNav',
  props: {
    items: {
      default: () => [
        {
          name: 'services',
          color: 'green',
          slug: 'creative/services'
        },
        {
          name: 'colorists',
          color: 'green',
          slug: 'creative/colorists'
        }
      ],
      type: Array
    },
    isActive: Boolean,
    isMobile: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

  .fki-sub-nav-container {
    position: fixed;
    border-bottom:1px solid rgba(255, 255, 255, 0.12);
    top: 70px;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 15px 0;
    height: 126px;
    display: flex;
    min-height: 1em;
    align-items: center;
    justify-content: center;
     padding: 0 24px;
         // align-items: flex-start;
    justify-content: flex-start;
    z-index: 100;
    .fki-sub-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      // height: 100%;
      width: 100%;
      .fki-nav {
        flex-wrap: wrap;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0;
        .fki-nav-item {
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
          position: relative;
          text-align: center;
          clear: none;
          height:30px;
          margin: 0 1.24vw;
          text-transform: uppercase;
          .fki-nav-item-link {
            text-decoration: none;
            color:#fff;
            font-size: 1rem;
            font-weight: bold;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
          }
        }
      }
      .fki-nav .fki-nav-item{
        .fki-nav-item-link{
          letter-spacing: 1px;
          line-height: 1.1rem;
          font-size: 1rem;
          vertical-align: baseline;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all .55s cubic-bezier(.25,.8,.25,1);
          &.router-link-exact-active {
            &.yellow{
            color: $fki-yellow;
            }
            &.green{
              color: $fki-green;
            }
            &.blue{
              color: $fki-blue;
            }
            &.pink{
              color: $fki-pink;
            }
            &.purple{
              color: $fki-purple;
            }
            &.orange{
              color: $fki-orange;
            }
          }
        }
        &:hover{
          .fki-nav-item-link.yellow{
          color: $fki-yellow;
          }
          .fki-nav-item-link.green{
            color: $fki-green;
          }
          .fki-nav-item-link.blue{
            color: $fki-blue;
          }
          .fki-nav-item-link.pink{
            color: $fki-pink;
          }
          .fki-nav-item-link.purple{
            color: $fki-purple;
          }
          .fki-nav-item-link.orange{
            color: $fki-orange;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .fki-sub-nav-container {
      position: fixed;
      border-bottom:1px solid rgba(255, 255, 255, 0.12);
      top: 131px;
      height:65px;
      align-items: center;
      justify-content: center;
     .fki-sub-nav .fki-nav {
        flex-wrap: nowrap;
     }
    }
   .fki-sub-nav-container .fki-sub-nav .fki-nav .fki-nav-item{
      .fki-nav-item-link{
        font-size: 1.1rem;
        letter-spacing: 1.1px;
        margin: 0 2vw;
        line-height: 1.5rem;
      }
    }
  }

    @media screen and (min-width: 960px) {
   .fki-sub-nav-container .fki-sub-nav .fki-nav .fki-nav-item{
      .fki-nav-item-link{
        font-size: 1.2rem;
        letter-spacing: 1.2px;
        margin: 0 2.24vw;
        line-height: 2rem;
      }
    }
  }
</style>
