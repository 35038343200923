<template>
  <div
    class="fki-search-wrapper"
    :class="showSearchResults? 'show-search-results': ''"
  >
    <div
      is-active
      class="fki-search-container"
      :class="showSearchResults? 'show-search-results': ''"
    >
      <div class="fki-search-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 12.27 12.27"
        > <title>search</title> <circle
          cx="5.14"
          cy="5.14"
          r="4.51"
          style="fill: none;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 1.25px"
        /> <line
          x1="8.34"
          y1="8.34"
          x2="11.64"
          y2="11.64"
          style="fill: none;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 1.25px"
        > <line /></line></svg>
      </div>
      <input
        id="searchField"
        v-model="searchTerm"
        name="search"
        :placeholder="placeholderText"
        tabindex="1"
        autofocus=""
        @input="checkSearch"
      >
      <div
        class="fki-clear-search"
        :class="{'active': showSearchResults}"
      >
        <a
          @click.stop="clearSearch()"
        >
          <span class="close">
            <svg
              id="38005175-180f-4794-8dcf-1de6492662f7"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 29.2 29.2"
            >
              <title>Close</title>
              <line
                class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
                x1="0.53"
                y1="0.53"
                x2="28.67"
                y2="28.67"
              />
              <line
                class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
                x1="0.53"
                y1="28.67"
                x2="28.67"
                y2="0.53"
              />
            </svg>
          </span>
          <span class="esc">[ESC]</span>
        </a>
      </div>
    </div>

    <!-- <div
      class="fki-search-ideas"
      :class="showSearchResults? 'show-search-results': ''"
    >
      <div
        v-if="isLoading"
        class="fki-line-loader"
      >
        <div class="fki-line-loader__element" />
      </div>
      <p v-if="!isLoading">
        Suggested search options:
        <span
          v-for="item, i in suggestions"
          :key="i"
          @click="doSearch(item.term)"
        >{{ item.term }}<span>; </span></span>
      </p>
    </div>

    <div
      v-if="featured.length"
      class="fki-featured-links"
      :class="showSearchResults? 'show-search-results': ''"
    >
      <h4>News and Links</h4>
      <a
        v-for="item, index in featured"
        :key="index"
        :href="item.link"
        target="_blank"
      >{{ item.name }}</a>
    </div> -->
  </div>
</template>

<script>
import Api from '@/services/api'
export default {
  name: 'FkiSearch',
  props: {
    placeholderText: {
      default: 'SEARCH HERE',
      type: String
    },
    showSearchResults: {
      default: true,
      type: Boolean
    },
    isActive: {
      default: true,
      type: Boolean
    }
  },
  emits: ['doSearch', 'clearSearch'],
  data () {
    return {
      isLoading: false,
      suggestions: ['Film', 'Digital Intermediate', 'Color', 'Remote Dailies', '65mm'],
      searchTerm: '',
      featured: []
    }
  },
  async created () {
    await this.checkParams()
  },
  methods: {
    async checkParams () {
      await this.getSuggestions()
      await this.getFeatured()
    },
    async getSuggestions () {
      this.isLoading = true
      try {
        const { data: result } = await Api().get('/suggestion')
        this.suggestions = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },

    async getFeatured () {
      try {
        const { data: result } = await Api().get('/featured')
        this.featured = result
      } catch (err) {
        console.log(err)
      }
    },

    checkSearch () {
      if (this.searchTerm.length >= 2) {
        this.doSearch(this.searchTerm)
      }
    },
    doSearch (item) {
      this.searchTerm = item
      this.$emit('doSearch', item)
    },
    clearSearch (e) {
      this.searchTerm = ''
      this.$emit('clearSearch', e)
    }
  }
}
</script>

<style lang="scss" scoped>

.fki-line-loader {
  background-color:transparent;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  z-index: 100000;
}

.fki-line-loader__element {
  height:3px;
  width: 100%;
  background: #fff;

}

.fki-line-loader__element:before {
  content: '';
  display: block;
  background-color: rgb(151, 135, 196);
  height: 3px;
  width: 0;
  animation: getWidth 2s ease-in infinite;
}

@keyframes getWidth {
  100% { width: 100%; }
}

.fki-search-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 0;
  flex-direction: column;
  width: 100%;
  left: 0;
  right: 0;
  height: 70vh;
  min-height: 300px;
  min-width: 350px;
  margin: 0 auto;
  height: auto;
  padding-bottom: 34vh;

  bottom: 55px;
  top: 33vh;
  transition: top .55s ease;
  &.show-search-results {
    top: 150px;
  }
  .fki-featured-links {
    padding-top: 2rem;
    text-align: center;
    font-size: 1.6rem;
    text-shadow: 1px 1px 1px #000;
    h4 {
      font-size: 2rem;
    }
    a {
      display: block;
      padding-bottom: 1rem;
      color: #2BA6DE;
      text-decoration: none;
    }
    &.show-search-results {
      opacity: 0;
      transition: opacity .15s;
    }
  }
  .fki-search-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    opacity: .78;
    background-color: rgba(255, 255, 255, 0.959);
    z-index: 0;
    zoom: 1;
    width: 40%;
    min-width: 350px;
    max-width:768px;
    padding-right: 20px;
    height: 52px;
    padding-top: 5px;
    background-size: cover;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.85);
    transition: width .55s ease, max-width .55s ease;
    input {
      display: inline-block;
      border: none;
      outline: 0;
      color: #a9a9a9;
      width: 100%;
      line-height: 1;
      min-width: 100%;
      vertical-align: text-bottom;
      background-color: transparent;
      font-size: 2.5rem;
      font-weight: 200;
    }
    &.show-search-results {
      top: 0;
      position: relative;
      background-color: transparent;
      transition: width .55s ease;
      margin-top: 0;
      z-index: 5;
      max-width: 100%;
      width: 100%;
      min-width: 0;
      opacity: 1;
      padding: 0 20px 0 10px;
      height: 80px;
      justify-content: flex-start!important;
      box-shadow: none;
      input {
        border: none;
        outline: 0;
        color: #fff !important;
        width: 100%;
        min-width: 90px;
        height: 80px;
        background-color: rgba(249,249,249,.12);
        padding: 0 25px;
        font-size: 63px;
      }
      .fki-search-icon {
        margin-left: 0;
        padding-top: 13px;
        margin-right: 20px;
        height: 81px;
        width: 87px;
        min-width: 20px;
        background-color: transparent;
        svg {
          height: 100%;
          width: 100%;
          stroke: #fff;
        }
      }
    }
    .fki-search-icon {
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 3px;
      height: auto;
      width: auto;
      min-width: 20px;
      display: block;
      background-color: transparent;
      svg {
        height: 1.45vw;
        width: 1.45vw;
        height: 2rem;
        width: 2rem;;
        stroke: #aaa;
      }
    }
    .fki-clear-search {
      color: #fff;
      display: block;
      width: auto;
      height: 100%;
      margin: 0;
      padding: 0;
      display:none;
      text-align: center;
      margin-left: 20px;
      padding-top:13px;
      cursor: pointer;
      transition: opacity ease-in-out .75s;
      &.active {
        display: block;
        opacity: 1;
      }
      .close {
        width: 36px;
        height: 36px;
        display: block;
        svg {
          fill: none;
          stroke: #fff;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }
      }
      .esc {
        font-size: 12px;
        padding-top: 10px;
        color: #b7b5b5;
        font-weight: 700;
        display: block;
      }
    }
  }

  .fki-search-ideas {
    opacity: 1;
    transition: opacity ease .4s .5s;
    min-height: 5px;
    width: 100%;
    width: 40%;
    padding: 15px 0 10px;;
    min-width: 250px;
    max-width: 768px;
    position: relative;
    display:none;
    p {
      font-size: 1.6rem;
      margin: 0;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
      span {
        cursor: pointer;
      }
      span:last-child{
       span {
        display: none;
        }
      }
    }
    &.show-search-results {
      opacity: 0;
      transition: opacity .15s;
    }
  }
  @media screen and (min-width: 768px) {
    .fki-search-ideas {
      display:block;
    }
  }

}
</style>
