import { createRouter, createWebHistory } from 'vue-router'
import { FKIDownload } from '@/components/FKIDownload'
import { FKIColorists } from '@/components/FKITalent'
import { FKICreativeServices } from '@/components/FKIServices'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    components: { home: Home },
    props: true
  },
  {
    path: '/search',
    name: 'Search',
    components: { home: Home }
  },
  {
    path: '/creative',
    name: 'Creative',
    component: () => import(/* webpackChunkName: "creative" */ '../views/Creative.vue'),
    children: [
      {
        path: 'services',
        name: 'CreativeServices',
        components: { services: FKICreativeServices },
        props: true
      },
      {
        path: 'colorists',
        name: 'Colorist',
        components: { colorists: FKIColorists },
        props: true
      }
    ]
  },
  {
    path: '/production',
    name: 'Production',
    component: () => import(/* webpackChunkName: "production" */ '../views/Production.vue')
  },
  {
    path: '/post-production',
    name: 'PostProduction',
    component: () =>
      import(/* webpackChunkName: "post-production" */ '../views/PostProduction.vue')
  },
  // Redirect this route to /gd-account-setup with Nginx
  // {
  //   path: "/gdaccountsetup",
  //   name: "GDAccountSetup",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "gdaccountsetup" */ "../views/GDAccountSetup.vue"
  //     ),
  // },
  {
    path: '/gd-account-setup',
    name: 'GDAccountSetup',
    component: () =>
      import(/* webpackChunkName: "gd-account-setup" */ '../views/GDAccountSetup.vue')
  },
  {
    path: '/gdaccountsetup',
    name: 'GDAS',
    component: () =>
      import(/* webpackChunkName: "gd-account-setup" */ '../views/GDAccountSetup.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    props: true
  },
  {
    path: '/contact/:facility?',
    name: 'ContactFacility',
    component: () => import(/* webpackChunkName: "facility" */ '../views/ContactFacility.vue'),
    props: true
  },
  // {
  //   path: '/companies',
  //   name: 'Companies',
  //   component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
  //   props: true
  // },
  {
    path: '/companies/:company?',
    name: 'Companies',
    component: () => import(/* webpackChunkName: "companies" */ '../views/Company.vue'),
    props: true
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/Careers.vue'),
    props: true
  },
  {
    path: '/careers/job/:jobId',
    name: 'Job',
    component: () => import(/* webpackChunkName: "job" */ '../views/Job.vue'),
    props: true
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
    props: true
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "download" */ '../views/Download.vue'),
    props: true,
    children: [
      {
        path: ':type/:file',
        name: 'Type',
        components: { download: FKIDownload },
        props: true
      }
    ]
  },
  {
    path: '/terms-and-conditions/:agreement?',
    name: 'TAC',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    props: true
  },
  {
    path: '/terms/:agreement?',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    props: true
  },
  {
    path: '/tv',
    name: 'TV',
    component: () => import(/* webpackChunkName: "tv" */ '../views/TV.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
