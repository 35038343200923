<template>
  <div
    v-if="loading"
    id="loader"
    class="fki-loader"
  >
    <div class="loading">
      <img
        :src="loaderImage"
        alt="Loading..."
      >
      <div class="loader-bg" />
    </div>
  </div>
</template>

<script>
import loader from '../../assets/PingPongColor.gif'
export default {
  name: 'fki-loader',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loaderImage: loader
    }
  }
}

</script>

<style lang="scss" scoped>
  .fki-loader {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:10995;
    opacity:1;
    text-align: center;
    display:block;
    display:block;
    .loading{
      position: relative;
      display: flex;
      z-index: 1;
      margin-top:0;
      padding-top:0;
      height: 100%;
      width: 100%;
      border-radius: 0;
      color: #fff;
      font-size: 30px;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        width:180px;
        margin:0 auto;
        height: auto;
        z-index:1;
        -webkit-transform:  scale(1);
        -moz-transform:  scale(1);
        -o-transform:  scale(1);
        -ms-transform:  scale(1);
        transform:  scale(1);
      }
      .loader-bg{
        position: absolute;
        top:0;
        bottom:0;
        right:0;
        width:100%;
        height:100%;
        opacity:.68;
        z-index:-1;
        background-color: #000;
      }
    }
  }
</style>
