<template>
  <div
    class="fki-block fki-search-result-block"
    :class="createClass"
    :style="createStyle"
  >
    <a
      v-if="category != 'Download' && destination != 'shiftai'"
      class="fki-search-link"
      :href="'/'+ destination"
    />
    <a
      v-if="category != 'Download' && destination == 'shiftai'"
      class="fki-search-link"
      href="https://fkai.fotokem.com"
    />
    <a
      v-if="category == 'Download'"
      :href="'/'+ destination"
      class="fki-search-link"
      target="_blank"
    />
    <div
      class="curtain swipe-down"
      :style="`animation-delay: ${time}ms; background-color: ${color}`"
    />
    <div
      class="fki-block-content"
      :style="`animation-delay: ${time}ms;`"
    >
      <div
        v-if="image"
        class="fki-block-image"
      >
        <img
          class="fki-image"
          :src="'https://static.fotokem.com/' + image"
          alt="{{title}}"
          :style="`animation-delay: ${time}ms;`"
        >
      </div>
      <div
        class="fki-block-text"
        :class="getColorClass(color)"
      >
        <div
          v-if="category"
          class="fki-block-category"
        >
          <p>{{ category }}</p>
        </div>
        <div
          v-if="title"
          class="fki-block-title"
        >
          <p>{{ title }}</p>
        </div>
        <div
          v-if="summary"
          class="fki-block-summary"
        >
          <p>{{ summary }}</p>
        </div>
      </div>
    </div>
    <a
      v-if="category != 'Download'"
      class="fki-search-block-contact"
      :style="`animation-delay: ${time}ms;`"
      @click="showSelectedPopup(department, title)"
    >
      <span v-if="category != 'Download'">Contact </span>
      <span
        v-if="category != 'Download'"
        v-html="department"
      />
      <svg
        v-if="category != 'Download'"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 182.22 196.91"
      >
        <title v-if="category != 'Download'">contact_icon</title>
        <path
          v-if="category != 'Download'"
          d="M230.48,322v99.83a25.27,25.27,0,0,1-25.2,25.2H160.64L91.27,493.71,103.71,447H73.46a25.27,25.27,0,0,1-25.2-25.2V322a25.27,25.27,0,0,1,25.2-25.2H205.28A25.27,25.27,0,0,1,230.48,322Zm-28.33,3.09H76.59v13.37H202.16V325.09Zm0,40.1H76.59v13.37H202.16V365.19Zm0,40.1H76.59v13.37H202.16V405.29Z"
          transform="translate(-48.26 -296.8)"
          style="fill: #fff"
        />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FkiSearchResultBlock',
  props: {
    category: {
      default: undefined,
      type: String
    },
    title: {
      default: undefined,
      type: String
    },
    time: {
      default: 36,
      type: Number
    },
    summary: {
      default: undefined,
      type: String
    },
    department: {
      default: undefined,
      type: String
    },
    destination: {
      default: undefined,
      type: String
    },
    image: {
      default: undefined,
      type: String
    },
    color: {
      default: undefined,
      type: String
    },
    height: {
      default: undefined,
      type: [Number, String]
    },
    width: {
      default: undefined,
      type: [Number, String]
    },
    maxHeight: {
      default: undefined,
      type: [Number, String]
    },
    maxWidth: {
      default: undefined,
      type: [Number, String]
    },
    minHeight: {
      default: undefined,
      type: [Number, String]
    },
    minWidth: {
      default: undefined,
      type: [Number, String]
    },
    rounded: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    },
    fixed: {
      default: false,
      type: Boolean
    },
    top: {
      default: undefined,
      type: Number
    },
    bottom: {
      default: undefined,
      type: Number
    },
    left: {
      default: undefined,
      type: Number
    },
    right: {
      default: undefined,
      type: Number
    }
  },
  emits: ['openContactPopup'],
  data () {
    return {
      componentClass: null,
      componentStyle: null
    }
  },
  computed: {
    createClass () {
      let componentClass = ''

      if (this.outlined) componentClass = 'outlined'
      if (this.rounded) componentClass = componentClass + ' rounded'
      if (this.fixed) componentClass = componentClass + ' fki-block-fixed'

      return componentClass
    },
    createStyle () {
      const componentStyle = {}

      if (this.height) componentStyle.height = this.height
      if (this.maxHeight) componentStyle.maxHeight = this.maxHeight
      if (this.minHeight) componentStyle.minHeight = this.minHeight
      if (this.width) componentStyle.width = this.width
      if (this.maxWidth) componentStyle.maxWidth = this.maxWidth
      if (this.minWidth) componentStyle.minWidth = this.minWidth

      if (this.fixed && this.top) componentStyle.top = this.top
      if (this.fixed && this.bottom) componentStyle.bottom = this.bottom
      if (this.fixed && this.left) componentStyle.left = this.left
      if (this.fixed && this.right) componentStyle.right = this.right
      return componentStyle
    }

  },
  methods: {
    showSelectedPopup (department, title) {
      console.log(department)
      console.log(title)
      this.$emit('openContactPopup', { department, title })
    },
    getColorClass (color) {
      console.log(color)
      let colorName = ''
      switch (color) {
        case '#E4DF1C':
          colorName = 'yellow'
          break
        case '#85C241':
          colorName = 'green'
          break
        case '#2BA6DE':
          colorName = 'blue'
          break
        case '#E51489':
          colorName = 'pink'
          break
        case '#804295':
          colorName = 'purple'
          break
        case '#E77926':
          colorName = 'orange'
          break

        default:
          break
      }

      return colorName
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

.fki-block{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .fki-search-block-contact {
    opacity: 0;
    position: absolute;
    z-index: 12000;
    bottom: 8px;
    right: 20px;
    cursor:pointer;
    -webkit-animation: showMe 1.5s step-end forwards;
      -o-animation: showMe 1.5s step-end forwards;
      animation: showMe 1.5s step-end forwards;
    svg {
      width: 18px;
      height: 18px;
      margin-bottom: -3px;
      margin-left:3px;
    }
    span {
      color: #fff;
      font-size: 13px;
      margin-right: 3px;
    }
  }
  .fki-search-link {
    display: block;
    width:100%;
    height:100%;
    z-index:10000;
  }
  &.fki-block-fixed {
    position: fixed;
    overflow-y: scroll;
    z-index:101;
  }
  &.outlined {
    border: solid 1px rgb(127, 127, 127);
  }
  &.rounded {
    border-radius: 4px;
  }
  &.fki-search-result-block {
    width: 300px;
    height: 300px;
    padding: 0;
    margin:10px;
    overflow: hidden;
    .curtain {
      top: 0;
      left: 0;
      height: 100%;
      background: #96cdc8;
      z-index: 15;
      width: 100%;
      position: absolute;
      &.swipe-down {
        top: -100%;
        animation: swipeDown 1.5s cubic-bezier(.6,0,.4,1) forwards;
      }
    }
    .fki-block-content {
      cursor: pointer;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: relative;
      padding: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom,rgba(0,0,0,.78) 0,rgba(0,0,0,.58) 39%,rgba(0,0,0,.58) 72%,rgba(0,0,0,.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000', GradientType=0 );
      position: absolute;
      left: 0;
      right: 0;
      padding: 0;
      bottom: 0;
      z-index: 11;
      -webkit-animation: showMe 1.5s step-end forwards;
      -o-animation: showMe 1.5s step-end forwards;
      animation: showMe 1.5s step-end forwards;
      visibility: visible;
    .fki-block-image{
      width: 100%;
      height: auto;
      clear: both;
      overflow: hidden;
      position: absolute;
      top:0;
      left:0;
      bottom:0;
      transition: border .55s ease;
      img {
        display: block;
        width: 100%;
        height:auto;
        position: absolute;
        object-fit: cover;
        object-position: center;
        height: 300px;
        width: 300px;
      }
    }
    .fki-block-text {
      clear: both;
      float: left;
      width: 100%;
      display: block;
      width: 100%;
      height:auto;
      position: absolute;
      background: linear-gradient(to bottom,rgba(0,0,0,.78) 0,rgba(0,0,0,.58) 39%,rgba(0,0,0,.58) 72%,rgba(0,0,0,.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000', GradientType=0 );
      padding:0 20px;
      height: 300px;
      width: 300px;
      text-align: left;
      cursor: pointer;
      -webkit-font-smoothing: antialiased;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid rgba(127, 127, 127, 0);
      transition: fill .65s ease, color .65s ease, border .65s ease;
      .fki-block-category {
        p {
          font-size: 1.4rem;
          text-transform: uppercase;
          margin-bottom:3px;
          font-weight: bold;
        }
      }
      .fki-block-title {
        p {
          font-size: 1.4rem;
          margin: 0;
          text-transform: capitalize;
        }
      }
      .fki-block-summary {
        font-weight: 400;
        position: absolute;
        display: -webkit-box;
        max-width: 265px;
        line-height: 1.3;
        -webkit-font-smoothing: antialiased;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 265px;
        bottom: 40px;
        font-size: 14px;
        max-height: 182px;
        p {
          font-size: 1.2rem;
          margin: 0;
          text-transform: none;
        }
      }
    }
  }
  &:hover {
    .fki-block-text {
      // border: 1px solid rgba(127, 127, 127, 0.35);
          transition: fill .65s ease, color .65s ease, border .65s ease;

          &.yellow{
            border: 1px solid $fki-yellow;
          }
          &.green{
            border: 1px solid $fki-green;
          }
          &.blue{
            border: 1px solid $fki-blue;
          }
          &.pink{
            border: 1px solid $fki-pink;
          }
          &.purple{
            border: 1px solid $fki-purple;
          }
          &.orange{
            border: 1px solid $fki-orange;
          }
    }

  }
   @keyframes swipeDown {
    0% {
      top: -100%;
    }
    50%,
    60% {
      top: 0;
    }
    100% {
      top: -100%;
    }
  }
  @keyframes showMe {
    from { opacity: 0; }
    65%, 100% { opacity: 1; }
  }
}
  }
</style>
