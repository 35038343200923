<template>
  <div
    class="fki-production-services"
  >
    <fki-page-slide
      v-if="slides.length && loadSlides"
      :slides="slides"
      :autoplay="true"
      :duration="5000"
    />
    <div class="content">
      <div
        v-for="service, index in services"
        :key="index"
        class="service-block"
      >
        <h3 class="service-title">
          {{ service.name }}
        </h3>
        <div
          v-for="item, i in service.items"
          :key="i"
          class="service-content"
        >
          <p class="service-subtitle">
            <span
              ng-mouseup="showSelectedPopup('Production Services',item.name)"
              v-html="item.name"
            />
            <span v-if="item.document">
              <a
                class="dll"
                :class="color"
                target="_blank"
                :href="item.document"
              >({{ item.downloadType }})</a>
            </span>
          </p>
          <div
            class="description"
            ng-mouseup="showSelectedPopup('Production Services',item.name)"
          >
            <ul>
              <li
                v-for="bullet, j in item.bullets"
                :key="j"
              >
                <span v-html="bullet.name" />
                <span v-if="bullet.document">
                  <a
                    target="_blank"
                    class="dll"
                    :class="color"
                    :href="bullet.document"
                  >({{ bullet.downloadType }})</a>
                </span>
                <span
                  v-if="bullet.image"
                  class="nf"
                  style="display:block; position:relative; clear:both;height:60px; width:60px; margin:5px 0"
                > <img
                  style="width:100%; height:auto"
                  :src="`/${bullet.image}`"
                > </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FKIPageSlide } from '../../components/FKISlides'

export default {
  name: 'FkiProductionServices',
  components: {
    'fki-page-slide': FKIPageSlide
  },
  props: {
    color: {
      default: undefined,
      type: String
    },
    column: {
      default: false,
      type: Boolean
    },
    height: {
      default: undefined,
      type: [Number, String]
    },
    width: {
      default: undefined,
      type: [Number, String]
    },
    maxHeight: {
      default: undefined,
      type: [Number, String]
    },
    maxWidth: {
      default: undefined,
      type: [Number, String]
    },
    minHeight: {
      default: undefined,
      type: [Number, String]
    },
    minWidth: {
      default: undefined,
      type: [Number, String]
    },
    rounded: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    },
    fixed: {
      default: false,
      type: Boolean
    },
    top: {
      default: undefined,
      type: Number
    },
    bottom: {
      default: undefined,
      type: Number
    },
    left: {
      default: undefined,
      type: Number
    },
    right: {
      default: undefined,
      type: Number
    },
    slides: {
      default: () => [],
      type: Array
    },
    services: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      loadSlides: false
    }
  },
  computed: {},
  created () {
    const widthBreak = '768'
    const heightBreak = '768'

    if (widthBreak > window.innerWidth || heightBreak > window.innerHeight) this.loadSlides = false
    else this.loadSlides = true
  },
  mounted () {
    const widthBreak = '768'
    const heightBreak = '768'

    if (widthBreak > window.innerWidth || heightBreak > window.innerHeight) this.loadSlides = false
    else this.loadSlides = true

    window.onresize = (event) => {
      if (widthBreak > window.innerWidth || heightBreak > window.innerHeight) this.loadSlides = false
      else this.loadSlides = true
      console.log(this.loadSlides)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-production-services {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width:100%;
  .content {
    padding: 0 5.55vw 45px;
    float: left;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .service-block {
      width: 100%;
      float: left;
      margin: 42px 0 0;
      min-height: 197px;
      padding: 0 6vw;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      ul {
        margin: 0;
        padding: 0 0 0 15px;
        list-style: disc;
        li {
          text-align: left;
          font-size: 14px;
        }
      }
      .service-content {
        padding-bottom: 15px;
      }
      .service-subtitle {
        color: #fff;
        font-size: 14px;
        line-height: 1.3;
        display: block;
        margin: 0 0 5px;
        text-transform: uppercase;
        clear: both;
         text-align: left;
         font-weight: bold;
      }
      .service-title {
        text-transform: uppercase;
        clear: both;
        font-size: 18px;
        line-height: 1.5;
        color: #fff;
        display: block;
        margin: 0 0 10px;
        text-align: left;
      }
    }
  }
  &.fki-production-services-fixed {
    position: fixed;
    overflow-y: scroll;
    z-index:101;
    bottom: 55px;
  }
  &.outlined {
    border: solid 1px rgb(127, 127, 127);
  }
  &.rounded {
    border-radius: 4px;
  }
  &.fki-flex-column {
   flex-direction: column;
  }
}
 @media screen and (min-width: 768px) {
  .fki-production-services .content .service-block {
    width: 50%;
  }
 }
</style>
