<template>
  <div
    class="fki-container-header"
    :class="createClass"
    :style="createStyle"
  >
    <h2
      class="fki-headline"
      :style="{'font-size':size}"
    >
      {{ title }}
    </h2>
    <a
      v-if="hasContact"
      class="fki-contact"
      @click.stop="openContact('production')"
    >
      <svg
        id="Layer_1"
        :class="color"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 182.22 196.91"
      >
        <title>contact_icon</title>
        <path
          d="M567.28,322v99.83a25.27,25.27,0,0,1-25.2,25.2H497.44l-69.37,46.68L440.51,447H410.26a25.27,25.27,0,0,1-25.2-25.2V322a25.27,25.27,0,0,1,25.2-25.2H542.08A25.27,25.27,0,0,1,567.28,322ZM539,325.09H413.39v13.37H539V325.09Zm0,26.74H413.39v13.37H539V351.82Zm0,26.74H413.39v13.37H539V378.56Zm0,26.74H413.39v13.37H539V405.29Z"
          transform="translate(-385.06 -296.8)"
        />
      </svg>
      <p :class="color">Contact</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FkiContainerHeader',
  props: {
    title: {
      default: undefined,
      type: String
    },
    color: {
      default: undefined,
      type: String
    },
    size: {
      default: undefined,
      type: String
    },
    height: {
      default: undefined,
      type: [Number, String]
    },
    width: {
      default: undefined,
      type: [Number, String]
    },
    maxHeight: {
      default: undefined,
      type: [Number, String]
    },
    maxWidth: {
      default: undefined,
      type: [Number, String]
    },
    minHeight: {
      default: undefined,
      type: [Number, String]
    },
    minWidth: {
      default: undefined,
      type: [Number, String]
    },
    rounded: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    },
    fixed: {
      default: false,
      type: Boolean
    },
    top: {
      default: undefined,
      type: Number
    },
    bottom: {
      default: undefined,
      type: Number
    },
    left: {
      default: undefined,
      type: Number
    },
    right: {
      default: undefined,
      type: Number
    },
    hasContact: {
      default: false,
      type: Boolean
    },
    contactTarget: {
      default: undefined,
      type: String
    }
  },
  emits: ['openContact'],
  data () {
    return {
      componentClass: null,
      componentStyle: null
    }
  },
  computed: {
    createClass () {
      let componentClass = ''

      if (this.outlined) componentClass = 'outlined'
      if (this.rounded) componentClass = componentClass + ' rounded'
      if (this.fixed) componentClass = componentClass + ' fki-fixed-container'
      if (this.color) componentClass = componentClass + ' ' + this.color

      return componentClass
    },
    createStyle () {
      const componentStyle = {}

      if (this.height) componentStyle.height = this.height
      if (this.maxHeight) componentStyle.maxHeight = this.maxHeight
      if (this.minHeight) componentStyle.minHeight = this.minHeight
      if (this.width) componentStyle.width = this.width
      if (this.maxWidth) componentStyle.maxWidth = this.maxWidth
      if (this.minWidth) componentStyle.minWidth = this.minWidth

      if (this.fixed && this.top) componentStyle.top = this.top
      if (this.fixed && this.bottom) componentStyle.bottom = this.bottom
      if (this.fixed && this.left) componentStyle.left = this.left
      if (this.fixed && this.right) componentStyle.right = this.right

      return componentStyle
    }
  },
  methods: {
    openContact (item) {
      this.$emit('openContact', item)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

  .fki-container-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width:100%;
    clear: both;
    text-align: center;
    &.fki-container-fixed {
      position: fixed;
      overflow-y: scroll;
    }
    &.outlined {
      border: solid 1px rgb(127, 127, 127);
    }
    &.rounded {
      border-radius: 4px;
    }
    &.yellow{
      color: $fki-yellow;
    }
    &.green{
      color: $fki-green;
    }
    &.blue{
      color: $fki-blue;
    }
    &.pink{
      color: $fki-pink;
    }
    &.purple{
      color: $fki-purple;
    }
    &.orange{
      color: $fki-orange;
    }
    .fki-headline{
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1;
      margin: 0;
      text-transform: uppercase;
    }
    .fki-contact {
      position: absolute;
      display: block;
      //width: 86px;
      height: 22px;
      top: 32px;
      right: 12px;
      color:#fff !important;
      text-decoration: none;
      transition: fill .65s ease, color .65s ease;
      cursor: pointer;
      p {
        color: #fff;
      }
      &:hover {
        transition: fill .65s ease, color .65s ease;
        p {
            transition: color .65s ease;
          &.yellow{
                color: $fki-yellow;
          }
          &.green{
            color: $fki-green;
          }
          &.blue{
            color: $fki-blue;
          }
          &.pink{
            color: $fki-pink;
          }
          &.purple{
            color: $fki-purple;
          }
          &.orange{
            color: $fki-orange;
          }
        }
        svg {
          &.yellow path{
                fill: $fki-yellow;
          }
          &.green path{
            fill: $fki-green;
          }
          &.blue path{
            fill: $fki-blue;
          }
          &.pink path{
            fill: $fki-pink;
          }
          &.purple path{
            fill: $fki-purple;
          }
          &.orange path{
            fill: $fki-orange;
          }
        }
      }
      svg {
        //padding-left: 65px;
        width: auto;
        height: 22px;
        path {
          fill: #fff;
          transition: fill .65s ease;
          &:hover {
            &.yellow{
                  fill: $fki-yellow;
            }
            &.green{
              fill: $fki-green;
            }
            &.blue{
              fill: $fki-blue;
            }
            &.pink{
              fill: $fki-pink;
            }
            &.purple{
              fill: $fki-purple;
            }
            &.orange{
              fill: $fki-orange;
            }
          }
        }
      }
      p {
        // width: 86px;
        margin-top: -2px;
        font-size: 12px;
        display: block;
        text-align: center;
      }
    }
  }

   @media screen and (min-width: 475px) {
    .fki-container-header .fki-headline{
      font-size: 1.8rem;
      line-height: 1;
    }
 }

 @media screen and (min-width: 768px) {
    .fki-contact {
      display: block !important;
    }
    .fki-container-header .fki-headline{
      font-size: 2.4rem;
      line-height: 1;
    }
 }

 @media screen and (min-width: 960px) {
       .fki-container-header .fki-headline{
      font-size: 3.0rem;
      line-height: 1;
    }
 }

</style>
