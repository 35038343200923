<template>
  <div class="fki-colorist">
    <div class="row">
      <div class="fki-colorist-image-container">
        <img
          class="fki-image"
          :src="'https://static.fotokem.com/' + colorist.image.src"
        >
      </div>
      <div class="fki-colorist-text-container">
        <h3 class="">
          {{ colorist.name }}
        </h3>
        <a
          class="fki-contact"
          @click.stop="openContact(colorist.name)"
        > <svg
          id="Layer_1"
          :class="color"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 182.22 196.91"
        > <title>contact_icon</title> <path
          d="M567.28,322v99.83a25.27,25.27,0,0,1-25.2,25.2H497.44l-69.37,46.68L440.51,447H410.26a25.27,25.27,0,0,1-25.2-25.2V322a25.27,25.27,0,0,1,25.2-25.2H542.08A25.27,25.27,0,0,1,567.28,322ZM539,325.09H413.39v13.37H539V325.09Zm0,26.74H413.39v13.37H539V351.82Zm0,26.74H413.39v13.37H539V378.56Zm0,26.74H413.39v13.37H539V405.29Z"
          transform="translate(-385.06 -296.8)"
        /></svg> <p
          :class="color"
        >Contact</p>
        </a>
        <p class="title ">
          {{ colorist.title }}
        </p>
        <p
          class="description "
          v-html="colorist.body"
        />
        <div
          v-if="colorist.recent.length >= 1"
          class="recent-project-list-container ng-scope"
          style=""
        >
          <p class="fki-project-list-heading">
            Recent Projects
          </p> <ul class="project-list">
            <li
              v-for="recentProject, i in colorist.recent"
              :key="i"
            >
              <a
                v-if="recentProject.video"
                style="display: block; position:absolute; width:100%; height:100%"
                target="_blank"
                :href="recentProject.video"
              /> <span>{{ recentProject.name }}</span>
              <span v-if="recentProject.studio">({{ recentProject.studio }})</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      v-if="colorist.past.length > 0"
      class="fki-past-projects-container"
    >
      <!-- <a
        v-if="currentProject >= 1"
        class="arrow prev"
        @click.stop="prevProject()"
      >
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13.17 58.6"
        >
          <title>arrow-left</title>
          <polygon
            points="13.17 58.6 0 29.3 13.17 0 13.17 58.6"
            style="fill: #fff"
          />
        </svg>
      </a>
      <a
        v-if="currentProject+4 < colorist.past.length"
        class="arrow next"
        @click.stop="nextProject()"
      >

        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13.17 58.6"
        >
          <title>arrow-left</title>
          <polygon
            points="13.17 58.6 0 29.3 13.17 0 13.17 58.6"
            style="fill: #fff"
          />
        </svg>
      </a> -->
      <p class="fki-project-list-heading">
        Past Projects
      </p>
      <div class="fki-project-list-container">
        <ul
          class="fki-project-list"
          :style="projectStyle"
        >
          <li
            v-for="pastProject, i in colorist.past"
            :key="i"
            class="fki-project-list-item"
          >
            <div class="fki-project-list-item-content">
              <a
                v-if="pastProject.video"
                target="_blank"
                :href="pastProject.video"
              >
                <span class="play">
                  <img src="/images/play.png">
                </span>
              </a>
              <p class="title">
                {{ pastProject.name }}
              </p>
              <p class="credit">
                {{ pastProject.studio }}
              </p>
            </div>
            <img
              :src="'https://static.fotokem.com/' + pastProject.image.src"
              :alt="pastProject.image.description"
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FkiColorist',
  components: {
  },
  props: {
    color: {
      default: '',
      type: String
    },
    colorist: {
      default: () => {},
      type: Object
    }
  },
  emits: ['openContact'],
  data () {
    return {
      currentProject: 0,
      projectStyle: ''
    }
  },
  computed: {
    createClass () {
      let componentClass = ''

      if (this.color) componentClass = this.color

      return componentClass
    }
  },
  methods: {
    openContact (item) {
      this.$emit('openContact', item)
    },
    nextProject () {
      this.currentProject = this.currentProject + 1
      if (this.currentProject > this.colorist.past.length - 1) {
        this.currentProject = 0
      }
      this.projectStyle = 'left:' + -249 * this.currentProject + 'px'
    },
    prevProject () {
      this.currentProject = this.currentProject - 1
      if (this.currentProject < 1) {
        this.currentProject = 0
      }
      this.projectStyle = 'left:' + -249 * this.currentProject + 'px'
    }
  }
}

</script>

<style lang="scss" scoped>
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

.fki-colorist {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  overflow-y: auto;
  align-items: flex-start;

  height: 90vh;
  background-color: #424242;
  flex-direction: column;
  .row{
    display:flex;
    position: relative;
    flex-direction: column;
  }
  .fki-colorist-image-container {
    position: relative;
    float: left;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .fki-past-projects-container {
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    bottom:0;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
    //height: 175px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-start;
    bottom: 0;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    .fki-project-list-heading {
      font-size: 12px;
      margin: 0 20px 8px;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
    }
    .arrow {
      position: absolute;
      top:75px;
      opacity:.52;
      transition: opacity .3s ease;
      cursor: pointer;
      &:hover {
        transition: opacity .3s ease;
        opacity:1;
      }
      svg {
        width: 12px;
        height: auto;
      }
    }
    .arrow.next {
      right: 3px;
      transform: rotateZ(180deg);
    }
    .arrow.prev {
      left: 3px;
    }

  }

  .fki-project-list-container {
    //height: 138px;
    //overflow: hidden;
    width: 100%;
    //overflow: hidden;
    position: relative;
   // height: 138px;
   width: 100%;
    position: relative;
    overflow-x: auto;
    height: auto;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-wrap: nowrap;
      width: 100%;
      height: auto;
      list-style: none;
      position: relative;
      flex-direction: column;
      // left:0;
      // transition: left .23s ease;
      margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    list-style: none;
    overflow-x: scroll;
    position: relative;
      li {
        background-color: #424242;
        width: 100%;
        float: left;
        margin-right: 0;
        margin-left: 0;
        display: block;
        height: 100%;
        padding: 0;
        line-height: 1.3;
        font-size: 14px;
        overflow: hidden;
        left: 0;
        position: relative;
        clear: none;
        margin-top: 0;

    float: left;
    margin-right: 0;
    margin-left: 0;
    display: block;
    height: 100%;
    padding: 0;
    line-height: 1.3;
    display: flex;
    font-size: 14px;
    flex-basis: 100%;
    min-width: 33.3333%;
    overflow: hidden;
    left: 0;
    position: relative;
    clear: none;
    margin-top: 0;
        .fki-project-list-item-content {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          transition: opacity .3s ease;
          .title {
            width: 100%;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translate(-50%,0);
            top: 55%;
            font-size: 14px;
            text-transform: uppercase;
             text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
             font-weight: bold;
          }
          .credit {
            width: 100%;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translate(-50%,0);
            top: 65%;
            font-size: 14px;
            font-weight: 400;
            text-transform: none;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
            font-weight: bold;
          }
          a {
            width: 100%;
            height: 100%;
            display: block;
          }
          &:hover{
            transition: opacity .3s ease;
            opacity: 1;
            background: linear-gradient(to bottom,rgba(0,0,0,.12) 0,transparent 10%,transparent 58%,rgba(0,0,0,.47) 97%,rgba(0,0,0,.47) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#52000000', endColorstr='#de000000', GradientType=0 );
            background: linear-gradient(to bottom,rgba(0, 0, 0, 0.194) 0,transparent 10%,transparent 58%,rgba(0, 0, 0, 0.499) 97%,rgba(0, 0, 0, 0.493) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#52000000', endColorstr='#de000000', GradientType=0 );
          }
        }
        .play {
          position: absolute;
          left: 50%;
          transform: translate(-50%,0);
          top: 33%;
          width: 12.5%;
          height: auto;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

    }
  }
  .fki-colorist-text-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    // overflow: hidden;
    // overflow-y: auto;
    align-items: flex-start;
    height: 100%;
    flex-direction: column;
    // max-height: 420px;
    padding-top: 29px;
    width: auto;
    padding-left: 2.43%;
    padding-right: 5%;
    float: left;
    // height: 420px;
    // max-height: 420px;
    padding-top: 29px;
   // width: 63%;
    width: auto;
    padding-left: 2.43%;
    padding-right: 5%;
    h3 {
      font-size: 18px;
      margin: 0 0 2px;
    }
    .title {
      margin: 0 0 23px;
      font-size: 16px;
    }
    .description {
      margin: 0 0 23px;
      line-height: 1.4;
      font-size: 14px;
      text-align: left;
      display: block;
      position:relative;
      height:100%;
    }
    .recent-project-list-container {
      position: relative;
      bottom: 0;
      display: block;
      text-align: left;
    }
    .fki-project-list-heading {
      font-size: 12px;
      line-height: 14px;
      margin: 0;
      padding-top: 10px;
      text-transform: uppercase;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    li {
      position: relative;
      display: block;
      float: left;
      clear: both;
      line-height: 14px;
      margin-top: 4px;
      font-size: 14px;
      }
    }
  }
  .fki-contact {
    position: absolute;
    display: block;
    width: 86px;
    height: 22px;
    top: 30px;
    right: 20px;
    color:#fff !important;
    text-decoration: none;
    transition: fill .65s ease, color .65s ease;
    p {
      color: #fff;
    }
    &:hover {
      transition: fill .65s ease, color .65s ease;
      p {
          transition: color .65s ease;
        &.yellow{
          color: $fki-yellow;
        }
        &.green{
          color: $fki-green;
        }
        &.blue{
          color: $fki-blue;
        }
        &.pink{
          color: $fki-pink;
        }
        &.purple{
          color: $fki-purple;
        }
        &.orange{
          color: $fki-orange;
        }
      }
      svg {
        &.yellow path{
          fill: $fki-yellow;
        }
        &.green path{
          fill: $fki-green;
        }
        &.blue path{
          fill: $fki-blue;
        }
        &.pink path{
          fill: $fki-pink;
        }
        &.purple path{
          fill: $fki-purple;
        }
        &.orange path{
          fill: $fki-orange;
        }
      }
    }
    svg {
      padding-left: 65px;
      width: 86px;
      height: 22px;
      path {
        fill: #fff;
        transition: fill .65s ease;
        &:hover {
          &.yellow{
            fill: $fki-yellow;
          }
          &.green{
            fill: $fki-green;
          }
          &.blue{
            fill: $fki-blue;
          }
          &.pink{
            fill: $fki-pink;
          }
          &.purple{
            fill: $fki-purple;
          }
          &.orange{
            fill: $fki-orange;
          }
        }
      }
    }
    p {
      width: 86px;
      margin-top: -2px;
      font-size: 12px;
      display: block;
      text-align: right;
    }
  }
}
@media screen and (min-width: 768px) {
  .fki-colorist {
    height: 100%;
    .row{
      flex-direction: row;
    }
    .fki-colorist-image-container {
      position: relative;
      float: left;
      // width: 379px;
      img {
        width: auto;
      }
    }
    .fki-project-list-container ul {
      flex-direction: row;
      li {
        min-width: 33.3333%;
      }
    }
  }
}
@media screen and (min-width: 960px) {
.fki-colorist {
   height: 100%;
  .row{
    flex-direction: row;
  }
   .fki-colorist-image-container {
    position: relative;
    float: left;
    // width: 379px;
    img {
      width: auto;
    }
  }
  .fki-project-list-container ul {
    flex-direction: row;
    li {
      min-width:25%;
      .fki-project-list-item-content {
        .title {
          width: 100%;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translate(-50%,0);
          top: 55%;
          font-size: 11px;
          text-transform: uppercase;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
            font-weight: bold;
        }
        .credit {
          width: 100%;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translate(-50%,0);
          top: 69%;
          font-size: 10px;
          font-weight: 400;
          text-transform: none;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
          font-weight: bold;
        }
        .play {
          position: absolute;
          left: 50%;
          transform: translate(-50%,0);
          top: 12%;
          width: 25%;
          height: auto;
        }
      }
    }
  }
}
}
</style>
