<template>
  <div class="fki-search-results-wrapper">
    <div
      class="fki-search-results-grid"
      :class="createClass"
      :style="createStyle"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FkiSearchResultsGrid',
  props: {
    color: {
      default: undefined,
      type: String
    },
    height: {
      default: undefined,
      type: [Number, String]
    },
    width: {
      default: undefined,
      type: [Number, String]
    },
    maxHeight: {
      default: undefined,
      type: [Number, String]
    },
    maxWidth: {
      default: undefined,
      type: [Number, String]
    },
    minHeight: {
      default: undefined,
      type: [Number, String]
    },
    minWidth: {
      default: undefined,
      type: [Number, String]
    },
    rounded: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    },
    fixed: {
      default: false,
      type: Boolean
    },
    top: {
      default: undefined,
      type: Number
    },
    bottom: {
      default: undefined,
      type: Number
    },
    left: {
      default: undefined,
      type: Number
    },
    right: {
      default: undefined,
      type: Number
    }
  },
  data () {
    return {
      componentClass: null,
      componentStyle: null
    }
  },
  computed: {
    createClass () {
      let componentClass = ''

      if (this.outlined) componentClass = 'outlined'
      if (this.rounded) componentClass = componentClass + ' rounded'
      if (this.fixed) componentClass = componentClass + ' fki-search-results-grid-fixed'

      return componentClass
    },
    createStyle () {
      const componentStyle = {}
      if (this.color) componentStyle.backgroundColor = this.color
      if (this.color) componentStyle.borderColor = this.color

      if (this.height) componentStyle.height = this.height
      if (this.maxHeight) componentStyle.maxHeight = this.maxHeight
      if (this.minHeight) componentStyle.minHeight = this.minHeight
      if (this.width) componentStyle.width = this.width
      if (this.maxWidth) componentStyle.maxWidth = this.maxWidth
      if (this.minWidth) componentStyle.minWidth = this.minWidth

      if (this.fixed && this.top) componentStyle.top = this.top
      if (this.fixed && this.bottom) componentStyle.bottom = this.bottom
      if (this.fixed && this.left) componentStyle.left = this.left
      if (this.fixed && this.right) componentStyle.right = this.right
      console.log(componentStyle)
      return componentStyle
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-search-results-wrapper{
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height:auto;
  width:100%;
  flex-direction: column;
  flex-grow:1;
  top:131px;
  padding:0 60px;
  bottom:55px;
  z-index: 105;
  background-color: #000;
}
.fki-search-results-grid{
  position: absolute;
 height: 100%;
    width: 100%;
    /* top: 120px; */
    max-width: 1600px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    overflow: hidden;
    flex-wrap: wrap;
    /* bottom: 55px; */
    overflow-y: auto;
  &.fki-search-results-grid-fixed {
    position: fixed;
    overflow-y: scroll;
    z-index:106;
  }
  &.outlined {
    border: solid 1px rgb(127, 127, 127);
  }
  &.rounded {
    border-radius: 4px;
  }
}

</style>
