<template>
  <div
    v-if="isActive"
    class="fki-footer"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FkiFooter',
  props: {
    isActive: Boolean,
    isMobile: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-footer {
  position:relative;
  bottom:0;
  width:100%;
  font-size:11px;
  text-align:center;
  color: #fff;
  height: 150px;
  font-size: .76vw;
  display:flex;
  align-items: center;
  justify-content:center;
  flex-direction: column-reverse;
  z-index:100;
  padding-top:10px;
  bottom:5px;
  // border-top:1px solid rgba(255, 255, 255, 0.12);
}

.home .fki-footer {
  bottom:0;
  position: fixed;
  font-size:11px;
  height: 150px;
  padding:10px 0 5px;
  .fki-copyright{
    margin: 10px 30px 10px 0px;
    font-size: 11px;
    display: block;
    line-height: 1.75;
  }
}
@media screen and (min-width: 320px) {
  .fki-footer {
    font-size:11px;
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 650px) {
  .fki-footer {
      height:70px;
      flex-direction: row;
      bottom:10px;
  }
  .home .fki-footer {
     height:70px;
     position: fixed;
  }
}

@media screen and (min-width: 1024px) {
  .fki-footer {
    bottom:0;
    font-size:11px;
    height: 50px;
    .copyright{
      margin: 10px 30px 10px 0px;
      font-size: 11px;
      display: block;
      line-height: 1.75;
    }
  }
}

</style>
