<template>
  <fki-container
    fixed
    top="131px"
    bottom="0"
    left="0"
    width="100%"
    class="home"
  >
    <fki-footer
      class="home"
      is-active
    >
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
  <fki-container-popup
    :is-active="showContactPopup"
    overlay
    persistent
    has-padding
    color="#424242"
    @close="closeContact()"
  >
    <fki-contact-form
      :current-service="currentService"
      :current-subject="currentSubject"
    />
  </fki-container-popup>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKIContainer, FKIContainerPopup } from '@/components/FKIContainer'
import { FKIFooterNav } from '@/components/FKINav'
import { FKIFooter } from '@/components/FKIApp'
import { FKIContactForm } from '@/components/FKIContact'

export default {
  name: 'Home',
  components: {
    'fki-container': FKIContainer,
    'fki-container-popup': FKIContainerPopup,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'fki-contact-form': FKIContactForm
  },
  props: {
    showSearch: {
      default: false,
      type: Boolean
    }
  },
  emits: ['doSearch', 'clearSearch', 'isLoading'],
  data () {
    return {
      page: {},
      pages: [],
      suggestions: [],
      placeholders: [],
      showContactPopup: false,
      currentService: null,
      currentSubject: null,
      contacts: {},
      searchTerm: '',
      searchResults: [],
      // showSearch: false,
      showResults: false,
      isLoading: false
    }
  },
  watch: {
    $route: 'checkParams'
  },
  async created () {
    await this.checkParams()
  },
  methods: {
    async checkParams () {
      await this.getPage()
    },
    async getPage () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/page/s/main')
        this.page = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async doSearch (e) {
      this.searchResults = []

      this.$emit('doSearch', e)
      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().post('/search', {
          lim: 12,
          off: 0,
          term: e
        })
        this.searchResults = result
        this.searchResults.forEach(element => {
          element.featuredImage = element.featuredImage.replace('image/', '')
        })
        this.showResults = true
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    clearSearch (e) {
      this.showResults = false
      this.$emit('clearSearch', e)
    },
    closeContact () {
      this.showContactPopup = false
    },
    openContact (item) {
      console.log('home openContact')
      console.log(item)
      this.currentService = item.department
      this.currentSubject = item.title
      this.showContactPopup = true
    }
  }
}
</script>
