<template>
  <div
    v-if="isActive"
    id="nav"
    class="fki-navbar-header"
    role="navigation"
  >
    <a href="/">
      <img
        class="fki-logo"
        :src="logo"
        :width="width"
        :height="height"
        :alt="alt"
      >
    </a>
  </div>
</template>

<script>

export default {
  name: 'FkiNavbar',
  props: {
    logo: {
      default: undefined,
      type: String
    },
    width: {
      default: '205',
      type: String
    },
    height: {
      default: '50',
      type: String
    },
    alt: {
      default: 'FotoKem',
      type: String
    },
    isActive: {
      default: true,
      type: Boolean
    },
    isMobile: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-navbar-header{
  display: flex;
  align-items: center;
  min-height: 1em;
  justify-content: center;
  height: 50px;
  margin-bottom: 15px;
z-index:105;
  .fki-logo {
    display: block;
    position: relative;
    height: 50px;
  }
}
 @media screen and (min-width: 768px) {
  .fki-navbar-header{
    height: 70px;
    margin-bottom: 24px;
  }
 }
</style>
