<template>
  <div
    class="fki-block fki-talent-block"
    :class="createClass"
    :style="createStyle"
  >
    <div class="fki-block-content">
      <div
        v-if="image"
        class="fki-block-image"
        :class="color"
      >
        <img
          class="fki-image"
          :src="'https://static.fotokem.com/' + image"
        >
      </div>
      <div
        class="fki-block-text"
        :class="color"
      >
        <div
          v-if="name"
          class="fki-block-name"
        >
          <p>
            {{ name }}
          </p>
        </div>
        <div
          v-if="title"
          class="fki-block-title"
        >
          <p>
            {{ title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FkiTalentBlock',
  props: {
    name: {
      default: undefined,
      type: String
    },
    title: {
      default: undefined,
      type: String
    },
    image: {
      default: undefined,
      type: String
    },
    color: {
      default: undefined,
      type: String
    },
    height: {
      default: undefined,
      type: [Number, String]
    },
    width: {
      default: undefined,
      type: [Number, String]
    },
    maxHeight: {
      default: undefined,
      type: [Number, String]
    },
    maxWidth: {
      default: undefined,
      type: [Number, String]
    },
    minHeight: {
      default: undefined,
      type: [Number, String]
    },
    minWidth: {
      default: undefined,
      type: [Number, String]
    },
    rounded: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    },
    fixed: {
      default: false,
      type: Boolean
    },
    top: {
      default: undefined,
      type: Number
    },
    bottom: {
      default: undefined,
      type: Number
    },
    left: {
      default: undefined,
      type: Number
    },
    right: {
      default: undefined,
      type: Number
    }
  },
  data () {
    return {
      componentClass: null,
      componentStyle: null
    }
  },
  computed: {
    createClass () {
      let componentClass = ''

      if (this.outlined) componentClass = 'outlined'
      if (this.rounded) componentClass = componentClass + ' rounded'
      if (this.fixed) componentClass = componentClass + ' fki-block-fixed'

      return componentClass
    },
    createStyle () {
      const componentStyle = {}
      // if (this.color) componentStyle.backgroundColor = this.color
      if (this.color) componentStyle.borderColor = this.color

      if (this.height) componentStyle.height = this.height
      if (this.maxHeight) componentStyle.maxHeight = this.maxHeight
      if (this.minHeight) componentStyle.minHeight = this.minHeight
      if (this.width) componentStyle.width = this.width
      if (this.maxWidth) componentStyle.maxWidth = this.maxWidth
      if (this.minWidth) componentStyle.minWidth = this.minWidth

      if (this.fixed && this.top) componentStyle.top = this.top
      if (this.fixed && this.bottom) componentStyle.bottom = this.bottom
      if (this.fixed && this.left) componentStyle.left = this.left
      if (this.fixed && this.right) componentStyle.right = this.right

      return componentStyle
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  $fki-border-yellow:  rgba(228, 221, 28, 0.3) !default;
  $fki-border-green:   rgba(134, 194, 65, 0.3) !default;
  $fki-border-blue:    rgba(43, 165, 222, 0.3) !default;
  $fki-border-pink:    rgba(229, 20, 138, 0.3) !default;
  $fki-border-purple:  rgba(128, 66, 149, 0.3) !default;
  $fki-border-orange:  rgba(231, 122, 38, 0.3) !default;
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;
.fki-block{
  position: relative;
  display: flex;
  &.fki-block-fixed {
    position: fixed;
    overflow-y: scroll;
    z-index:101;
  }
  &.outlined {
    border: solid 1px rgb(127, 127, 127);
  }
  &.rounded {
    border-radius: 4px;
  }
  &.fki-talent-block {
    width: 340px;
    height: 380px;
    padding: 64px 59px 0;
    .fki-block-content {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: relative;
      padding: 0;
      .fki-block-image{
        width: 100%;
        height: 230px;
        clear: both;
        overflow: hidden;
        float: left;
        transition: border .55s ease;
        border: 1px solid rgba(127, 127, 127, 0.0);
        img {
          display: block;
          width: 100%;
          height:auto;
        }
      }
      .fki-block-text {
        clear: both;
        float: left;
        width: 100%;
        text-align: center;
        cursor: pointer;
        .fki-block-name {
          p {
            transition: color .65s ease;
            font-size: 1.4rem;
            text-transform: uppercase;
            margin-bottom:3px;
            font-weight: bold;
          }
        }
        .fki-block-title {
          p {
            transition: color .65s ease;
            font-size: 1.4rem;
            margin: 0;
            text-transform: capitalize;
          }
        }
      }
      &:hover {
        .fki-block-image {
          transition: border .65s ease, color .65s ease;
          &.yellow{
            border: 1px solid $fki-border-yellow;
          }
          &.green{
            border: 1px solid $fki-border-green;
          }
          &.blue{
            border: 1px solid $fki-border-blue;
          }
          &.pink{
            border: 1px solid $fki-border-pink;
          }
          &.purple{
            border: 1px solid $fki-border-purple;
          }
          &.orange{
            border: 1px solid $fki-border-orange;
          }
        }
        .fki-block-text {
           &.yellow{
            .fki-block-name {
              p {
                transition: color .65s ease;
                color: $fki-yellow;
              }
            }
            .fki-block-title {
              p {
                transition: color .65s ease;
                color: $fki-yellow;
              }
            }
          }
          &.green{
            .fki-block-name {
              p {
                transition: color .65s ease;
                color: $fki-green;
              }
            }
            .fki-block-title {
              p {
                transition: color .65s ease;
                color: $fki-green;
              }
            }
          }
          &.blue{
            .fki-block-name {
              p {
                transition: color .65s ease;
                color: $fki-blue;
              }
            }
            .fki-block-title {
              p {
                transition: color .65s ease;
                color: $fki-blue;
              }
            }
          }
          &.pink{
            .fki-block-name {
              p {
                transition: color .65s ease;
                color: $fki-pink;
              }
            }
            .fki-block-title {
              p {
                transition: color .65s ease;
                color: $fki-pink;
              }
            }
          }
          &.purple{
            .fki-block-name {
              p {
                transition: color .65s ease;
                color: $fki-purple;
              }
            }
            .fki-block-title {
              p {
                transition: color .65s ease;
                color: $fki-purple;
              }
            }
          }
          &.orange{
            .fki-block-name {
              p {
                transition: color .65s ease;
                color: $fki-orange;
              }
            }
            .fki-block-title {
              p {
                transition: color .65s ease;
                color: $fki-orange;
              }
            }
          }
        }
      }
    }
  }
}

</style>
