<template>
  <div
    v-if="isActive"
    class="fki-preloader"
  />
</template>

<script>
export default {
  name: 'fki-preloader',
  props: {
    isActive: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: -1000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &.active {
    z-index: 10950;
    opacity: 0.68;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }
}
</style>
