<template>
  <div
    class="fki-slideshow"
    :class="tv? 'tv': ''"
  >
    <vueper-slides
      fade
      fixed-height="100vh"
      :pause-on-hover="false"
      :bullets="false"
      :autoplay="autoplay"
      :duration="duration"
      :disable="isPaused"
      :arrows="false"
      :init-slide="initSlide"
    >
      <vueper-slide
        v-for="(slide) in slides"
        :key="slide.identifier"
        :title="slide.title"
        :content="slide.credit"
        :image="'https://static.fotokem.com/' + slide.src"
      >
        <template #content>
          <div
            class="vueperslide__content-wrapper"
          >
            <div
              v-if="shadow"
              class="fki-slideshow-shadow"
            />
            <div
              v-if="overlay"
              class="fki-slideshow-overlay"
            />
            <div
              v-if="!isPaused"
              class="fki-slide-text"
            >
              <h3>{{ slide.title }}</h3>
              <p v-html="slide.credit" />
              <span class="fki-slide-copyright">
                &copy; {{ slide.copyright }}
              </span>
            </div>
            <div
              v-if="tv && slide.watermark"
              class="fki-slide-watermark"
            >
              <img
                class="fki-watermark-image"
                :src="slide.watermark"
              >
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'FkiFullSlide',
  components: { VueperSlides, VueperSlide },
  props: {
    title: {
      default: '',
      type: String
    },
    duration: {
      default: 6000,
      type: Number
    },
    initSlide: {
      default: 1,
      type: Number
    },
    autoplay: {
      default: true,
      type: Boolean
    },
    isPaused: {
      default: false,
      type: Boolean
    },
    slides: {
      default: () => [],
      type: Array
    },
    options: {
      default: () => {},
      type: Object
    },
    tv: {
      default: false,
      type: Boolean
    },
    shadow: {
      default: false,
      type: Boolean
    },
    overlay: {
      default: false,
      type: Boolean
    }
  }
  // setup (props) {
  //   console.log(props.title)
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .fki-slideshow .fki-slide .fki-slide-text {
    opacity: 0;
  }

 .fki-slideshow-shadow {
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 3;
    left: 0;
    right: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.78) 0%, rgba(0,0,0,0) 38%, rgba(0,0,0,0) 64%, rgba(0,0,0,0.52) 100%);
  }
  .fki-slideshow-overlay {
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1;
    left: 0;
    right: 0;
    opacity: .78;
    background-color: #000;
  }
  .fki-slideshow {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    padding: 0;
    margin: 0;
    z-index: 0;
    bottom: 0;
    right: 0;
    .fki-slide-text {
      z-index: 105;
      display: flex;
      position: absolute;
      padding-bottom: 35px;
      text-align: center;
      color: #fff;
      padding-top: 300px;
      bottom: 45px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width:100%;
      opacity: 0;
      h3 {
        font-size: 2.2rem;
        margin: 5px 0;
         text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
      }
      p {
        font-size: 1.8rem;
        margin: 0;
         text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
      }
    }
    .fki-slide-copyright {
      font-size: 0.76vw;
      padding-right: 0;
      z-index: 105;
      opacity: 0;
      width: 100%;
      font-size: 10px;
      color: #fff;
      padding-top: 15px;
      padding-right: 0;
      text-align: center;
      margin: 0;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    }
    .fki-slide-watermark {
        display: none;
    }
    &.tv {
      .fki-slide-text {
      z-index: 105;
      display: flex;
      position: absolute;
      padding-bottom: 0;
      text-align: center;
      color: #fff;
      padding-top: 300px;
      bottom: 40px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width:100%;
      h3 {
        font-size: 3.448vw;
        margin: 20px 0;
        opacity: 1;
         text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
      }
      p {
        font-size: 2.6146vw;
        margin: 0 0 20px 0;
        opacity: 1;
         text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
      }
    }
    .fki-slide-copyright {
      bottom: 0;
      width: 100%;
      font-size: 36px;
      font-size: 1vw;
      text-align: right;
      padding-bottom: 1.125vw;
      padding-right: 3.125vw;
      opacity: .86;
       text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    }
    .fki-slide-watermark {
      width: 11%;
      position: absolute;
      z-index: 500;
      left: 0;
      bottom: 0;
      display: block;
      padding-bottom: 45px;
      padding-left: 3.125vw;
      img {
        display: block;
        width: 100%;
        position: relative;
        opacity: .65;
      }
    }
  }
  }

 @media screen and (min-width: 768px) {
.fki-slideshow .fki-slide .fki-slide-text, .fki-slideshow .fki-slide .fki-slide-copyright {
    opacity: 0.58;
  }
 }

</style>
