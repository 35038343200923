<template>
  <fki-colorist-grid>
    <fki-talent-block
      v-for="colorist in colorists"
      :key="colorist.identifier"
      :name="colorist.name"
      :title="colorist.title"
      :image="colorist.image.src"
      :color="color"
      @click="select(colorist)"
    />
  </fki-colorist-grid>
</template>

<script>
import { FKITalentBlock, FKIColoristGrid } from '@/components/FKITalent'

export default {
  name: 'FkiColorists',
  components: {
    'fki-talent-block': FKITalentBlock,
    'fki-colorist-grid': FKIColoristGrid
  },
  props: {
    colorists: {
      default: () => [],
      type: Array
    },
    color: {
      default: undefined,
      type: String
    }
  },
  emits: ['click'],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    select (item) {
      this.$emit('click', item)
    }
  }
}

</script>
<style lang="scss" scoped>
</style>
