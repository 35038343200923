<template>
  <fki-loader :loading="isLoading" />
  <fki-full-slide
    v-if="backgrounds.length"
    :slides="backgrounds"
    :overlay="slideOverlay"
    :is-paused="isPaused"
    :autoplay="!isPaused"
    :init-slide="initSlide"
    :shadow="hasShadow"
    :tv="isTV"
  />
  <fki-header
    v-if="!hideFeatures"
    :is-active="true"
    :class="$route.name === 'Home'? 'home' : ''"
  >
    <fki-navbar-header
      v-if="!hideFeatures"
      :is-active="true"
      :logo="logo"
    />
    <fki-navbar
      v-if="!hideFeatures"
      :is-active="true"
      :items="pages"
      @openSearch="openSearch"
      @doSearch="doSearch"
      @clearSearch="clearSearch"
    />
  </fki-header>
  <router-view
    name="home"
    :show-search="showSearch"
    @doSearch="doSearch"
    @clearSearch="clearSearch"
    @isLoading="setIsLoading"
  />
  <router-view
    :show-search="showSearch"
    @isLoading="setIsLoading"
    @setBackgrounds="setBackgrounds"
    @doSearch="doSearch"
    @clearSearch="clearSearch"
  />
  <fki-container
    v-if="showResults"
    fixed
    top="131px"
    bottom="0"
    left="0"
    width="100%"
    class="search"
  >
    <fki-search-results-grid
      v-if="showResults"
      :is-active="showResults"
      :show-search-results="showResults"
    >
      <fki-search-result-block
        v-for="item in searchResults"
        :key="item.identifier"
        :category="item.category"
        :summary="item.summary"
        :department="item.department"
        :destination="item.destination"
        :title="item.title"
        :image="item.featuredImage"
        :color="`#${item.color}`"
        :time="item.time"
        @openContactPopup="openContact"
      />
    </fki-search-results-grid>
    <fki-footer
      class="home"
      is-active
    >
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
  <fki-container-popup
    :is-active="showContactPopup"
    overlay
    persistent
    has-padding
    color="#424242"
    @close="closeContact()"
  >
    <fki-contact-form
      :current-service="currentService"
      :current-subject="currentSubject"
    />
  </fki-container-popup>
</template>

<script>
import Api from '@/services/api'
import { FKILoader } from '@/components/FKILoader'
import { FKIContainer, FKIContainerPopup } from '@/components/FKIContainer'
import { FKIFullSlide } from '@/components/FKISlides'
import { FKIHeader } from '@/components/FKIApp'
import { FKINavbar, FKINavbarHeader } from '@/components/FKINav'
import { FKIContactForm } from '@/components/FKIContact'
import { FKISearchResultsGrid, FKISearchResultBlock } from '@/components/FKISearch'
import logo from '@//assets/fotokem-main-digital-web2.png'

export default {
  name: 'App',
  title: 'App',
  components: {
    'fki-header': FKIHeader,
    'fki-navbar': FKINavbar,
    'fki-container': FKIContainer,
    'fki-container-popup': FKIContainerPopup,
    'fki-contact-form': FKIContactForm,
    'fki-navbar-header': FKINavbarHeader,
    'fki-full-slide': FKIFullSlide,
    'fki-loader': FKILoader,
    'fki-search-results-grid': FKISearchResultsGrid,
    'fki-search-result-block': FKISearchResultBlock
  },
  data: () => ({
    isLoading: false,
    logo: logo,
    slideOverlay: false,
    hasShadow: false,
    isPaused: false,
    hideFeatures: false,
    showSearch: false,
    showContactPopup: false,
    currentService: null,
    currentSubject: null,
    initSlide: 1,
    isTV: false,
    showResults: false,
    searchResults: [],
    backgrounds: [],
    pages: []
  }),
  watch: {
    $route: 'checkParams'
  },
  async created () {
    await this.getPages()

    if (this.$route.name === 'TV') await this.getTvBackgrounds()
    else await this.getBackgrounds()

    this.initSlide = Math.floor(Math.random() * this.backgrounds.length)

    await this.checkParams()
  },
  methods: {
    setIsLoading (b) {
      this.isLoading = b
    },
    async getPages () {
      this.isLoading = true

      try {
        const { data: result } = await Api().get('/pages')
        this.pages = result
      } catch (err) {
        // console.log(err)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    async getBackgrounds () {
      try {
        const { data: result } = await Api().get('/backgrounds')
        this.backgrounds = result
        this.backgrounds.forEach(element => {
          element.src = element.src.replace('image/', '')
        })
      } catch (err) {
        // console.log(err)
      }
    },
    async setBackgrounds (slides) {
      this.backgrounds = slides
    },
    async getTvBackgrounds () {
      try {
        const { data: result } = await Api().get('/page/s/television')
        this.backgrounds = result.slides
        this.backgrounds.forEach(element => {
          element.src = element.src.replace('image/', '')
        })
      } catch (err) {
        // console.log(err)
      }
    },
    openSearch (e) {
      this.showSearch = !this.showSearch
    },
    async doSearch (e) {
      console.log('s')
      this.slideOverlay = true
      this.isPaused = true
      this.searchResults = []
      try {
        this.isLoading = false
        const { data: result } = await Api().post('/search', {
          lim: 12,
          off: 0,
          term: e
        })
        this.searchResults = result
        this.searchResults.forEach(element => {
          element.featuredImage = element.featuredImage.replace('image/', '')
        })
        this.showResults = true
        console.log(this.searchResults)
      } catch (err) {
        // console.log(err)
      } finally {
        this.isLoading = false
      }
    },
    clearSearch (e) {
      this.slideOverlay = false
      this.isPaused = false
      this.showResults = false
    },
    closeContact () {
      this.showContactPopup = false
    },
    openContact (item) {
      this.currentService = item.department
      this.currentSubject = item.title
      this.showContactPopup = true
    },
    async checkParams () {
      if (this.$route.name === 'TV') {
        this.isTV = true
        this.hideFeatures = true
        this.hasShadow = false
        this.isPaused = false
        this.slideOverlay = false
      } else if (this.$route.path === '/' || this.$route.name === 'Companies' || this.$route.name === 'Company') {
        this.isPaused = false
        this.slideOverlay = false
        this.hasShadow = true
        this.hideFeatures = false
        this.isTV = false
      } else {
        this.hideFeatures = false
        this.isTV = false
        this.hasShadow = true
        this.slideOverlay = true
        this.isPaused = true
      }
      this.initSlide = Math.floor(Math.random() * this.backgrounds.length)
    }
  }
}
</script>

<style lang="scss">
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

  *, :after, :before {
      box-sizing: border-box;
  }
  html {
    font-size:10px;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    width:100%;
    height: 100%;
    background-color: #000000;
  }

  p, a, span, h1, h2, h3, h4, h5, h6 {
    &.yellow{
      color: $fki-yellow;
    }
    &.green{
      color: $fki-green;
    }
    &.blue{
      color: $fki-blue;
    }
    &.pink{
      color: $fki-pink;
    }
    &.purple{
      color: $fki-purple;
    }
    &.orange{
      color: $fki-orange;
    }
 }
  .underline {
    text-decoration: underline;
  }

  .capitalize {
    text-transform: uppercase;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
    background-color: #000000;
    width:100%;
    height:auto;
    position: relative;
  }

  // .home {
  //   display: flex;
  //   position: relative;
  // ex:16000 !important;

  .fki-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    min-height: 100%;
  }

  @media screen and (min-width: 768px ) {
    .fki-page {
      top: 131px;
    }
  }

  /* Animations */
  @keyframes swipeDown {
    50%,
    60% {
      top: 0;
    }
    100% {
      top: -100%;
    }
  }

  @keyframes fadeOut {
    50%,
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes showMe {
    from {
      opacity: 0;
    }
    60%,
    100% {
      opacity: 1;
    }
  }
</style>
