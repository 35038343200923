<template>
  <div
    v-if="overlay"
    class="fki-container-popup-wrapper-overlay"
    :class="isActive? 'open': ''"
    @click="close('wrapper')"
  />
  <div
    class="fki-container-popup-wrapper"
    :class="isActive? 'open': ''"
    @click="close('wrapper')"
  >
    <div
      class="fki-container-popup"
      :class="createClass"
      :style="createStyle"
    >
      <a
        class="close"
        @click.stop="close('button')"
      >
        <svg
          id="38005175-180f-4794-8dcf-1de6492662f7"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 29.2 29.2"
        >
          <title>Close</title>
          <line
            class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
            x1="0.53"
            y1="0.53"
            x2="28.67"
            y2="28.67"
          />
          <line
            class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
            x1="0.53"
            y1="28.67"
            x2="28.67"
            y2="0.53"
          />
        </svg>
      </a>
      <slot
        v-if="isActive"
        :color="color"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FkiContainerPopup',
  props: {
    color: {
      default: undefined,
      type: String
    },
    column: {
      default: false,
      type: Boolean
    },
    height: {
      default: undefined,
      type: [Number, String]
    },
    width: {
      default: undefined,
      type: [Number, String]
    },
    maxHeight: {
      default: undefined,
      type: [Number, String]
    },
    maxWidth: {
      default: undefined,
      type: [Number, String]
    },
    minHeight: {
      default: undefined,
      type: [Number, String]
    },
    minWidth: {
      default: undefined,
      type: [Number, String]
    },
    rounded: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    },
    fixed: {
      default: false,
      type: Boolean
    },
    top: {
      default: undefined,
      type: [Number, String]
    },
    bottom: {
      default: undefined,
      type: [Number, String]
    },
    left: {
      default: undefined,
      type: [Number, String]
    },
    right: {
      default: undefined,
      type: [Number, String]
    },
    overlay: {
      default: true,
      type: Boolean
    },
    hasPadding: {
      default: false,
      type: Boolean
    },
    persistent: {
      default: true,
      type: Boolean
    },
    isActive: {
      default: false,
      type: Boolean
    }
  },
  emits: ['close'],
  data () {
    return {
      componentClass: null,
      componentStyle: null
    }
  },
  computed: {
    createClass () {
      let componentClass = ''

      if (this.outlined) componentClass = 'outlined'
      if (this.rounded) componentClass = componentClass + ' rounded'
      if (this.hasPadding) componentClass = componentClass + ' has-padding'
      if (this.fixed) componentClass = componentClass + ' fki-container-fixed'
      if (this.column) componentClass = componentClass + ' fki-flex-column'

      return componentClass
    },
    createStyle () {
      const componentStyle = {}
      if (this.color) componentStyle.backgroundColor = this.color
      if (this.color) componentStyle.borderColor = this.color

      if (this.height) componentStyle.height = this.height
      if (this.maxHeight) componentStyle.maxHeight = this.maxHeight
      if (this.minHeight) componentStyle.minHeight = this.minHeight
      if (this.width) componentStyle.width = this.width
      if (this.maxWidth) componentStyle.maxWidth = this.maxWidth
      if (this.minWidth) componentStyle.minWidth = this.minWidth

      if (this.fixed && this.top) componentStyle.top = this.top
      if (this.fixed && this.bottom) componentStyle.bottom = this.bottom
      if (this.fixed && this.left) componentStyle.left = this.left
      if (this.fixed && this.right) componentStyle.right = this.right
      return componentStyle
    }
  },
  methods: {
    close (e) {
      if (e === 'button' || !this.persistent) this.$emit('close', e)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-container-popup-wrapper-overlay {
  position: fixed;
  top: -200%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 150!important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  opacity: 0;
  transition: top .1s .3s, opacity .3s;
  &.open {
    top:  0;
    opacity: .86;
    transition:  opacity .5s;
  }
}
.fki-container-popup-wrapper {
  position: fixed;
  top: -200%;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 16004!important;
  scale: (0);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  transition: scale .3s, opacity .3s;
  &.open {
    scale: (1);
    transition: scale .3s, opacity .3s;
    opacity: 1;
    z-index: 1000;
    left: 0;
    top: 0;
  }
  .fki-container-popup {
    overflow: visible;
    position: relative;
    z-index: 16005;
    // max-width: 1030px;
    height: auto;
    margin: auto auto;
    padding-bottom: 0;
    top: 0;
    left: 0;
    width: 85%;
    background-color: #ccc;;
    min-height:1em;
    display: flex;
    align-items: flex-start;
    opacity: 1;
    &.fki-container-fixed {
      position: fixed;
      overflow-y: scroll;
      z-index:101;
    }
    &.outlined {
      border: solid 1px rgb(127, 127, 127);
    }
    &.rounded {
      border-radius: 4px;
    }
    &.has-padding {
      padding:20px;
    }
    &.fki-flex-column {
      flex-direction: column;
      align-items: center;
    }

  }
    .close {
      right: auto;
      width: 32px;
      height: 32px;
      top: 0;
      left: 100%;
      margin-left: 15px;
      opacity: 1;
      cursor: pointer;
      transition: opacity .65s ease .65s;
      position: absolute;
      z-index: 10001;
      svg {
        width: 100%;
        height: 100%;
         fill: none;
          stroke: #fff;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
      }
    }
}
  @media screen and (min-width: 1040px) {
    .fki-container-popup  {
      width:75% !important;
    }
  }

    @media screen and (min-width: 1200px) {
    .fki-container-popup  {
      width:60% !important;
    }
  }
</style>
