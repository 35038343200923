<template>
  <div
    v-if="isActive"
    id="nav"
    class="fki-navbar"
    role="navigation"
  >
    <div
      class="hamburger"
      :class="navOpen?'closed':'open'"
      @click="openMenu()"
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15.34 15.32"
      >
        <title>menu</title>
        <line
          x1="14.59"
          y1="7.66"
          x2="0.75"
          y2="7.66"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
        <line
          x1="14.59"
          y1="0.75"
          x2="0.75"
          y2="0.75"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
        <line
          x1="14.59"
          y1="14.57"
          x2="0.75"
          y2="14.57"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
      </svg>
    </div>
    <div
      class="close-mobile"
      :class="navOpen?'open':'closed'"
      @click="closeMenu()"
    >
      <svg
        id="38005175-180f-4794-8dcf-1de6492662f7"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29.2 29.2"
      >
        <title>Vector Smart Object10</title>
        <line
          class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
          x1="0.53"
          y1="0.53"
          x2="28.67"
          y2="28.67"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
        <line
          class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
          x1="0.53"
          y1="28.67"
          x2="28.67"
          y2="0.53"
          style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-width: 1.5px;
                  "
        />
      </svg>
    </div>
    <div
      class="small flex-container center-center"
    >
      <ul
        class="fki-nav fki-navbar-nav-mobile"
        :class="navOpen?'open':'closed'"
      >
        <li
          v-for="item, index in items"
          :key="index"
          class="fki-nav-item"
          @click="closeMenu()"
        >
          <router-link
            :to="`/${item.slug}`"
            :class="createLinkClass(item)"
            class="fki-nav-item-link"
          >
            {{ item.name }}
          </router-link>
        </li>
        <li class="fki-nav-item">
          <div class="fki-search-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 12.27 12.27"
            >
              <title>search</title>
              <circle
                cx="5.14"
                cy="5.14"
                r="4.51"
                style="fill: none;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 1.25px"
              />
              <line
                x1="8.34"
                y1="8.34"
                x2="11.64"
                y2="11.64"
                style="fill: none;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 1.25px"
              />
            </svg>
          </div>
        </li>
      </ul>
    </div>

    <div
      v-if="isActive"
      class="large flex-container center-center"
    >
      <ul class="fki-nav fki-navbar-nav">
        <li
          v-for="item, index in items"
          :key="index"
          class="fki-nav-item"
          @click="clearSearch(item)"
        >
          <router-link
            :to="`/${item.slug}`"
            :class="createLinkClass(item)"
            class="fki-nav-item-link"
          >
            {{ item.name }}
          </router-link>
        </li>
        <li
          class="fki-nav-item"
        >
          <div
            class="fki-search-icon"
            @click="toggleSearch($event)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 12.27 12.27"
            >
              <title>search</title>
              <circle
                cx="5.14"
                cy="5.14"
                r="4.51"
                style="fill: none;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 1.25px"
              />
              <line
                x1="8.34"
                y1="8.34"
                x2="11.64"
                y2="11.64"
                style="fill: none;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 1.25px"
              />
            </svg>
          </div>
          <div
            v-if="showSearch"
            class="fki-search"
          >
            <input
              id="searchField"
              v-model="searchTerm"
              name="search"
              :placeholder="placeholderText"
              tabindex="1"
              autofocus=""
              @input="checkSearch"
            >
            <div
              class="fki-clear-search"
              :class="{'active': showSearchResults}"
            >
              <a
                @click.stop="clearSearch()"
              >
                <span class="close">
                  <svg
                    id="38005175-180f-4794-8dcf-1de6492662f7"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 29.2 29.2"
                  >
                    <title>Close</title>
                    <line
                      class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
                      x1="0.53"
                      y1="0.53"
                      x2="28.67"
                      y2="28.67"
                    />
                    <line
                      class="85fd8afb-c29e-49f9-80e0-7c6c72622650"
                      x1="0.53"
                      y1="28.67"
                      x2="28.67"
                      y2="0.53"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import Api from '@/services/api'

export default {
  name: 'FkiNavbar',
  props: {
    items: {
      default: () => [
        {
          name: 'creative',
          color: 'green',
          slug: 'creative'
        },
        {
          name: 'production',
          color: 'blue',
          slug: 'production'
        },
        {
          name: 'post-production',
          color: 'pink',
          slug: 'post-production'
        },
        {
          name: 'companies',
          color: 'yellow',
          slug: 'companies'
        },
        {
          name: 'contact',
          color: 'orange',
          slug: 'contact'
        }
      ],
      type: Array
    },
    isActive: {
      type: Boolean,
      default: true
    },
    isMobile: Boolean
  },
  emits: ['openSearch', 'hideSearchResults', 'doSearch', 'clearSearch', 'isLoading'],
  data () {
    return {
      navOpen: false,
      searchTerm: '',
      searchResults: [],
      showSearch: false,
      showResults: false
    }
  },
  methods: {
    toggleSearch (e) {
      this.showSearch = !this.showSearch
      this.$emit('openSearch', e)
    },
    checkSearch () {
      if (this.searchTerm.length >= 2) {
        this.doSearch(this.searchTerm)
      }
    },
    openMenu () {
      this.navOpen = true
      this.showSearch = false
    },
    closeMenu () {
      this.navOpen = false
      this.showSearch = false
      this.clearSearch(null)
    },
    hideSearchResults () {
      this.$emit('hideSearchResults')
    },
    createLinkClass (item) {
      let isActive = ''
      const slug = this.$route.path.split('/')
      if (slug[1] === item.slug) isActive = 'is-active'
      return `${item.color} ${isActive}`
    },

    async doSearch (e) {
      this.$emit('doSearch', e)
    },
    clearSearch (e) {
      this.showResults = false
      this.searchTerm = ''
      this.$emit('clearSearch', e)
    },
    closeContact () {
      this.showContactPopup = false
    },
    openContact (item) {
      console.log('nav openContact')
      console.log(item)
      this.currentService = item.department
      this.currentSubject = item.title
      this.showContactPopup = true
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  $fki-yellow:  #E4DF1C !default;
  $fki-green:   #85C241 !default;
  $fki-blue:    #2BA6DE !default;
  $fki-pink:    #E51489 !default;
  $fki-purple:  #804295 !default;
  $fki-orange:  #E77926 !default;

  .fki-navbar {
    display: block;
    position: relative;
    height: 0;
    width: 0;
    .fki-search {
      display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    input {
      display: inline-block;
      border: none;
      border-radius: 2px;
      outline: 0;
      color: #707070;
      width: 100%;
      line-height: 1;
      min-width: 100%;height: 34px;
      padding:4px 8px;
      vertical-align: text-bottom;
      background-color: #fff;
      font-size: 2rem;
      font-weight: 200;
    }
    }
    .close {
      padding-left: 8px;
      svg {
        height: 16px;
        width: 16px;
        stroke: #fff;
      }
    }
    .fki-search-icon {
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 0;
      height: auto;
      width: auto;
      min-width: 20px;
      display: block;
      background-color: transparent;
      svg {
        height: 18px;
        width: 18px;
        stroke: #fff;
      }
    }
    .hamburger, .close-mobile{
     width: 70px;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 20px 20px 20px;
      &.open{
        display: block;
      }
       &.closed{
        display: none;
      }
    }
    .fki-nav {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      padding: 0;
      display:none;
      &.fki-navbar-nav-mobile {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -110%;
        right: 0;
        width: 100%;
        padding-top: 90px;
        justify-content: flex-start;
        align-items: center;
        background-color: #000;
        height: 100%;
        margin: 0;
        opacity: 0.95;
        transition: left .55s cubic-bezier(.25,.8,.25,1);
        &.open {
           transition: left .55s cubic-bezier(.25,.8,.25,1);
          left: 0;
        }
        .fki-nav-item {
          margin: 10px 0;
          .fki-nav-item-link {
            text-decoration: none;
            color:#fff;
            font-size: 16px;
          }
        }
      }
      .fki-nav-item {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        float: left;
        position: relative;
        text-align: center;
        clear: none;
        height:34px;
        margin: 0 2.24vw;
        text-transform: uppercase;
        cursor: pointer;
        .fki-nav-item-link {
          text-decoration: none;
          color:#fff;
          font-size: 12px;
          font-weight: bold;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
        }
      }
    }
    .fki-nav .fki-nav-item{
      .fki-nav-item-link{
        letter-spacing: 1.25px;
        line-height: 2rem;
        font-size: 1.2rem;
        font-size: 12px;
        // height: 34px;
        vertical-align: middle;
        display: inline-block;
        transition: all .55s cubic-bezier(.25,.8,.25,1);
        &.router-link-exact-active, &.router-link-active, &.is-active {
          &.yellow{
          color: $fki-yellow;
          }
          &.green{
            color: $fki-green;
          }
          &.blue{
            color: $fki-blue;
          }
          &.pink{
            color: $fki-pink;
          }
          &.purple{
            color: $fki-purple;
          }
          &.orange{
            color: $fki-orange;
          }
        }
      }
      &:hover{
        .fki-nav-item-link.yellow{
        color: $fki-yellow;
        }
        .fki-nav-item-link.green{
          color: $fki-green;
        }
        .fki-nav-item-link.blue{
          color: $fki-blue;
        }
        .fki-nav-item-link.pink{
          color: $fki-pink;
        }
        .fki-nav-item-link.purple{
          color: $fki-purple;
        }
        .fki-nav-item-link.orange{
          color: $fki-orange;
        }
      }
    }
  }

@media screen and (min-width: 768px) {
  .fki-navbar {
    height: 70px;
    width: 100%;
    .hamburger {display:none !important;}
    .fki-nav {
      display: block;
      margin: 0;
      padding: 0;
      opacity: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transform: translate(0,0);
      transition: transform .55s,opacity .5s ease;
    }
  }
}
</style>
